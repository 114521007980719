import React, { useState, useEffect } from "react";
import classes from "./appointment.module.css";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Appointmentbannerimg from "../assets/Book.jpg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import appointmentService from "./api/appointmentService";
import departmentsService from "./api/departmentsService";
import doctorsService from "./api/doctorsService";
import * as Yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { AdapterDayjs, formatDate } from "@mui/x-date-pickers/AdapterDayjs";

function Appointment() {
  const [departments, setDepartments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [department, setDepartment] = useState("");
  const [doctor, setDoctor] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      date: "",
      department_id: "",
      doctor_id: "",
      address: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      last_name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      phone: Yup.string().required("Required"),
      department_id: Yup.string().required("Required"),
      doctor_id: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      address: Yup.string().required("Required"),
      date: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { setStatus, resetForm }) => {
      try {
        console.log(values);
        await appointmentService.create(values);
        // Add any further logic upon successful submission
        resetForm();
      } catch (error) {
        console.error("Error submitting appointment:", error);
        setStatus("error"); // Set an error status to display a general error message
        // for example
        resetForm();
      }
    },
  });

  const onDepartmentChange = async (e) => {
    const departmentId = e.target.value;
    setDepartment(departmentId);
    formik.setFieldValue("department_id", e.target.value);

    try {
      const response = await departmentsService.showById(departmentId);
      const data = response.data.data;
      setDoctors(data.doctors);
    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  };

  useEffect(() => {
    const departmentsFetch = async () => {
      try {
        const response = await departmentsService.index();
        const data = response.data.data;
        setDepartments(data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    departmentsFetch();
  }, []);

  return (
    <>
      <div
        className={classes.Appointmentbanner}
        style={{ backgroundImage: `url(${Appointmentbannerimg})` }}
      >
        {/* <div className={classes.Appointmentbanneroverlay}></div> */}
      </div>

      <div className={classes.Appointmentformhead}>
        <h3>Patient Portal</h3>
        <h1>Appointment</h1>
      </div>
      <div className={classes.Appointmentform}>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ width: "45%" }} className={classes.formfeild}>
            <p>Patient First Name:</p>
            <OutlinedInput
              placeholder="Patient First Name"
              name="first_name"
              className={classes.feildtext}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <p className={classes.formValidation}>
                {formik.errors.first_name}
              </p>
            )}
          </FormControl>
          <FormControl sx={{ width: "45%" }} className={classes.formfeild}>
            <p>Patient Last Name:</p>
            <OutlinedInput
              placeholder="Patient Last Name"
              name="last_name"
              className={classes.feildtext}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <p className={classes.formValidation}>
                {formik.errors.last_name}
              </p>
            )}
          </FormControl>
          <FormControl sx={{ width: "45%" }} className={classes.formfeild}>
            <p>Mobile:</p>
            <OutlinedInput
              placeholder="Enter Your Mobile"
              name="phone"
              className={classes.feildtext}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className={classes.formValidation}>{formik.errors.phone}</p>
            )}
          </FormControl>
          <FormControl sx={{ width: "45%" }} className={classes.formfeild}>
            <p>E-mail:</p>
            <OutlinedInput
              placeholder="Enter Your E-mail id"
              name="email"
              className={classes.feildtext}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <p className={classes.formValidation}>{formik.errors.email}</p>
            )}
          </FormControl>
          <FormControl sx={{ width: "45%" }} className={classes.formfeild}>
            <p>Select Department:</p>
            <Select
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={department}
              onChange={onDepartmentChange}
              className={classes.feildtext}
            >
              <MenuItem disabled value="">
                <em>Select</em>
              </MenuItem>
              {departments.map((department) => (
                <MenuItem key={department.id} value={department.id}>
                  {department.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: "45%" }} className={classes.formfeild}>
            <p>Select Doctor:</p>
            <Select
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={doctor}
              name="doctor_id"
              label="Doctor"
              onChange={(e) => {
                setDoctor(e.target.value);
                formik.setFieldValue("doctor_id", e.target.value);
              }}
              className={classes.feildtext}
            >
              <MenuItem disabled value="">
                <em>Select</em>
              </MenuItem>
              {doctors.map((doctor) => (
                <MenuItem key={doctor.id} value={doctor.id}>
                  {doctor.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "45%" }} className={classes.formfeild}>
            <p>Date:</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(startDate)}
                onChange={(date) => {
                  setStartDate(date.toDate());
                  formik.setFieldValue(
                    "date",
                    dayjs(date).format("YYYY-MM-DD") // Format the date for submission
                  );
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <OutlinedInput {...startProps} label="Date" />
                    {formik.touched.date && formik.errors.date && (
                      <p className={classes.formValidation}>
                        {formik.errors.date}
                      </p>
                    )}
                  </>
                )}
                format="DD-MM-YYYY" // Format the displayed date
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl sx={{ width: "45%" }} className={classes.formfeild}>
            <p>Address:</p>
            <OutlinedInput
              placeholder="Address"
              name="address"
              className={classes.feildtext}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
            {formik.touched.address && formik.errors.address && (
              <p className={classes.formValidation}>{formik.errors.address}</p>
            )}
          </FormControl>

          <div className={classes.applybtndiv}>
            <button
              className={`${classes.applybtn} ${
                formik.isSubmitting ? classes.disabled : ""
              }`}
              type="submit"
              disabled={formik.isSubmitting}
            >
              <strong>Apply</strong>
            </button>
            {formik.isSubmitting && (
              <p className={classes.formValidation}>Submitting...</p>
            )}
            {formik.status === "error" && (
              <p className={classes.formValidation}>
                Error submitting the form. Please try again.
              </p>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default Appointment;
