import React from "react";
import classes from "./Home.module.css";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Newshome from "./Newshome";
import Patientshome from "./Patientshome";
import Facilitieshome from "./Facilitieshome";
import Nearbyhome from "./Nearbyhome";
import Doctorshome from "./Doctorshome";
import Depthome from "./Depthome";
import Bannerhome from "./Bannerhome";
import Welcome from "./Welcome";
import Award from "./Award";


function Home() {
    return (
        <>
        <Bannerhome />
        <Welcome />
        <Doctorshome />
        <Depthome />
            <div className={classes.Facilitieshomebg}>
                <Facilitieshome />
            </div>
            <Patientshome />
            <Newshome />
            {/* <Award /> */}
            <Nearbyhome />
        </>
    );
}

export default Home;