import React from 'react';

function Privacy(props) {
    return (
        <>
        
            
        </>
    );
}

export default Privacy;