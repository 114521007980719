import React, { useEffect, useState} from "react";
import classes from "./Home.module.css";
import BlogData from "../blog/BlogData";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { BsArrowRight } from "react-icons/bs";
import { useQuery } from "react-query";
import AOS from "aos";
import "aos/dist/aos.css";
function Newshome(props) {
  // const [items, setItems] = useState(BlogData.slice(0, 3));  
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  });
  const { isLoading, error, data } = useQuery("Newsdata", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/news-limit").then((res) =>
    res.json()
  )
);
const [items, setItems] = useState(data);
if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;


  return (
    <>
      <div className={classes.blogcontainerStyle}  data-aos="fade-up">
        <h1>News, Events and Blogs</h1>
        <p>
        Explore research-based health and medical information, and get updates on events happening at MES Hospital. 
        Stay in the loop with the most recent medical discoveries and news through this section.
        </p>
        <Grid container spacing={2}>
        {data.data.map((elem,i) => {
            const { id, title, image, description } = elem;

            return (
              <Grid item md={4} key={id} style={{ marginTop: '20px' }}  data-aos="fade-up">
                <Link
                  to={`/news-events/${elem.slug}`}
                  className={classes.blogurl}
                >
                  <div className={classes.blogItem}>
                    <img
                      src={process.env.REACT_APP_PUBLIC_URL + "/" + elem.image}
                      alt={elem.title}
                      className={classes.blogimg}
                    />
                    <h2>{elem.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: elem.short_description }}></p>
                  </div>
                </Link>
              </Grid>
            );
          })}
        </Grid>
        <p className={classes.viewbtn}>
          <Link to="/news-events">View All &nbsp;<BsArrowRight /></Link>
        </p>
      </div>
    </>
  );
}

export default Newshome;
