import newsimg from "../../assets/news.jpg"
import newsimg1 from "../../assets/news1.jpg"
import newsimg2 from "../../assets/news2.jpg"

const TipsData = [
  {
    id: 201,
    title: "Lorem ipsum",
    image: newsimg1,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 202,
    title: "Lorem ipsum",
    image: newsimg,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 203,
    title: "Lorem ipsum",
    image: newsimg2,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 204,
    title: "Lorem ipsum",
    image: newsimg2,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 205,
    title: "Lorem ipsum",
    image: newsimg1,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 206,
    title: "Lorem ipsum",
    image: newsimg,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 207,
    title: "Lorem ipsum",
    image: newsimg,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 208,
    title: "Lorem ipsum",
    image: newsimg2,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 209,
    title: "Lorem ipsum",
    image: newsimg1,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
];

export default TipsData;