import React from "react";
import classes from "./Award.module.css";
import awardbannerimg from "../../assets/Optimized-DJI_0419.jpg";
import { AiFillStar } from "react-icons/ai";
import Grid from '@mui/material/Grid';

const AwardList = [
  {
    id: 1,
    title: "Award 1",
  },
  {
    id: 2,
    title:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui.",
  },
  {
    id: 3,
    title: "Award 3",
  },
  {
    id: 4,
    title: "Award 4",
  },
  {
    id: 5,
    title: "Achievement",
  },
  {
    id: 6,
    title: "Achievement 1",
  },
  {
    id: 7,
    title: "Achievement 2",
  },
];

function Award(props) {
  return (
    <>
      <div
        className={classes.awardbanner}
        style={{ backgroundImage: `url(${awardbannerimg})` }}
      >
        <div className={classes.awardbanneroverlay}>
          <div className={classes.awardbannercon}>
            <h1>Awards & Achievements</h1>
            <p>
              <strong>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui.
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.awardbody}>
        <ul style={{ listStyleType: "none" }} className={classes.awardlist}>
          {AwardList.map((item) => (
            <li key={item.id}>
              <Grid container spacing={2} className={classes.awardlistcon}>
                <Grid item xs={1}>
                  <AiFillStar style={{fontSize: '22px'}}/>
                </Grid>
                <Grid item xs={11}>
                  <h3>{item.title}</h3>
                </Grid>
              </Grid>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Award;
