import React, { useState, useRef, useEffect } from "react";
import classes from "./Career.module.css";
import careerbannerimg from "../../assets/banner7.jpg";
import { useParams } from "react-router-dom";
import CareerData from "./CareerData";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";

function CareerDetail(props) {
  const { id } = useParams();
  const { isLoading, error, data } = useQuery("careerdetails", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/careers/" + id).then((res) =>
      res.json()
    )
  );

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Resume, setResume] = useState(null);
  const [Error, setError] = useState("");
  const [create, setCreate] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const nameInput = useRef(null);
  const emailInput = useRef(null);
  const messageInput = useRef(null);
  const mobileInput = useRef(null);
  const resumeInput = useRef(null);

  useEffect(() => {
    // Reset state after a delay when the form is successfully submitted
    if (isFormSubmitted) {
      const timeoutId = setTimeout(() => {
        setIsFormSubmitted(false);
        setName("");
        setEmail("");
        setMobile("");
        setResume(null);
        setMessage("");
        setError("");
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isFormSubmitted]);

  async function loginHandle(e) {
    e.preventDefault();

    try {
      var filter =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (Name.length === 0) {
        setError("Please enter your Full Name");
        nameInput.current.focus();
      } else if (Name !== Name.trim()) {
        setError("Space is not allowed first in Name");
        nameInput.current.focus();
      } else if (Email.length === 0) {
        setError("Please enter your E-mail Address");
        emailInput.current.focus();
      } else if (!filter.test(Email)) {
        setError("This is not a valid email format!");
        emailInput.current.focus();
      } else if (Mobile.length === 0) {
        setError("Please enter your Phone Number");
        mobileInput.current.focus();
      } else if (!/^\d{10}$/.test(Mobile)) {
        setError("Please enter a valid 10-digit Phone Number");
        mobileInput.current.focus();
      } else if (Message.length === 0) {
        setError("Please enter your Message");
        messageInput.current.focus();
      } else if (Message !== Message.trim()) {
        setError(
          "Please write something without leading or trailing white spaces"
        );
        messageInput.current.focus();
      } else if (!Resume) {
        setError("Please upload your resume");
        resumeInput.current.click(); // Trigger file input click to bring attention to the missing file
      } else {
        // Prepare form data to include the file
        const formData = new FormData();
        formData.append("name", Name);
        formData.append("email", Email);
        formData.append("mobile", Mobile);
        formData.append("message", Message);
        formData.append("resume", Resume);
        // Make a POST request to the Laravel API endpoint
        const response = await axios.post(
          process.env.REACT_APP_PUBLIC_API_URL + "/careers/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle the API response as needed
        console.log(response.data);
        setCreate("Your Message Successfully Sent");

        // Reset form fields
        setName("");
        setEmail("");
        setMobile("");
        setMessage("");
        setResume(null);
        setError(""); // Clear any previous errors
        // Set the form submission status to trigger the useEffect
        setIsFormSubmitted(true);
      }
    } catch (error) {
      // Handle errors
      console.error("Error submitting contact form:", error);
    }
  }

  if (isLoading) return "Loading...";
  if (error) return "An error has occurred: " + error.message;

  const careerDetails = data; // Adjust this line based on the structure of your API response

  return (
    <>
      <div
        className={classes.careerbanner}
        style={{ backgroundImage: `url(${careerbannerimg})` }}
      >
        <div className={classes.careerbanneroverlay}>
          <div className={classes.careerbannercon}>
            <h1>Career</h1>
            <p>
              <strong></strong>
            </p>
          </div>
        </div>
      </div>

      <div className={classes.careerdetailbody}>
        <div className={classes.careerdetailcon}>
          <div className={classes.formsection}>
            <form onSubmit={loginHandle}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Designation"
                    defaultValue={careerDetails.data.title}
                    sx={{
                      width: "80%",
                      marginTop: "20px",
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Department"
                    defaultValue={careerDetails.data.departments.name}
                    sx={{
                      width: "80%",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  />
                </Grid>
              </Grid>
              <Divider />

              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    value={Name}
                    id="outlined-basic"
                    label="Name"
                    inputRef={nameInput}
                    variant="outlined"
                    className={classes.textfield}
                    sx={{
                      width: "80%",
                      marginTop: "20px",
                      "& .MuiInputLabel-root.Mui-focused": { color: "#800020" },
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          border: "3px solid #800020",
                        },
                      },
                    }}
                  />
                  {Error && nameInput.current === document.activeElement && (
                    <div className={classes.Error}>{Error}</div>
                  )}
                </Grid>
                <Grid item md={6}>
                  <TextField
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    value={Email}
                    id="outlined-basic"
                    label="E-mail"
                    inputRef={emailInput}
                    variant="outlined"
                    className={classes.textfield}
                    sx={{
                      width: "80%",
                      marginTop: "20px",
                      "& .MuiInputLabel-root.Mui-focused": { color: "#800020" },
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          border: "3px solid #800020",
                        },
                      },
                    }}
                  />
                  {Error && emailInput.current === document.activeElement && (
                    <div className={classes.Error}>{Error}</div>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    onChange={(event) => {
                      setMobile(event.target.value);
                    }}
                    value={Mobile}
                    id="outlined-basic"
                    label="Phone Number"
                    inputRef={mobileInput}
                    variant="outlined"
                    className={classes.textfield}
                    sx={{
                      width: "80%",
                      marginTop: "20px",
                      "& .MuiInputLabel-root.Mui-focused": { color: "#800020" },
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          border: "3px solid #800020",
                        },
                      },
                    }}
                  />
                  {Error && mobileInput.current === document.activeElement && (
                    <div className={classes.Error}>{Error}</div>
                  )}
                </Grid>
                <Grid item md={6}>
                  <TextField
                    onChange={(event) => {
                      setMessage(event.target.value);
                    }}
                    value={Message}
                    id="outlined-multiline-static"
                    label="Write your message here"
                    inputRef={messageInput}
                    multiline
                    rows={3}
                    className={classes.textfield}
                    sx={{
                      width: "80%",
                      marginTop: "20px",
                      "& .MuiInputLabel-root.Mui-focused": { color: "#800020" },
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          border: "3px solid #800020",
                        },
                      },
                    }}
                  />
                  {Error && messageInput.current === document.activeElement && (
                    <div className={classes.Error}>{Error}</div>
                  )}
                </Grid>
              </Grid>
              <p className={classes.subhead}>
                Upload Resume:
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) => setResume(e.target.files[0])}
                  ref={resumeInput}
                />
              </p>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.textfield}
                  sx={{
                    width: "50%",
                    height: "56px",
                    marginTop: "20px",
                    fontSize: "18px",
                    backgroundColor: "#800020",
                    "&:hover": {
                      background: "#800020",
                    },
                    letterSpacing: "1.5px",
                  }}
                >
                  Send
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CareerDetail;
