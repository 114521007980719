import patientimg from "../../assets/patient.jpg"
import patientimg1 from "../../assets/patient1.jpg"
import patientimg2 from "../../assets/patient2.jpg"

const PatientsData = [
  {
    id: 201,
    name: "Lorem ipsum",
    image: patientimg,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 202,
    name: "Lorem ipsum",
    image: patientimg1,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 203,
    name: "Lorem ipsum",
    image: patientimg2,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 204,
    name: "Lorem ipsum",
    image: patientimg1,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 205,
    name: "Lorem ipsum",
    image: patientimg,
    date: "02/06/2022",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
];

export default PatientsData;