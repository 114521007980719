import React from "react";
import { useParams } from "react-router-dom";
import Docs from "./doctorsdata";
import classes from "./Doctors.module.css";
import docbannerimg from "../../assets/od.jpg";
import Divider from "@mui/material/Divider";
import { useQuery } from "react-query";

const Doctordetail = () => {
  const { id } = useParams();

  // Find the doctor with the matching ID from the Docs array
  // const doctor = Docs.find((doc) => doc.id === parseInt(id));

  // Handle the case when the doctor is not found
  // if (!doctor) {
  //   return <div style={{ margin: "50px" }}>Doctor not found.</div>;
  // }

  const { slug } = useParams();


  const { isLoading, error, data } = useQuery("DoctorDetail", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/doctors/" + slug).then((res) =>
    res.json()
  )
  );
  if (isLoading) return "Loading...";
  
  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <div
        className={classes.docbanner}
        style={{ backgroundImage: `url(${docbannerimg})`, }}
        
      >
        <div >
          <div className={classes.docbannercon}>
            
            <p>
              {/* <strong>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute
                irur tu dolo end erit in volup tate velit ese.
              </strong> */}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className={classes.docdetailbody}>
          <img src={process.env.REACT_APP_PUBLIC_URL + "/" + data.data.image} alt={data.data.name} style={{"height":"404px"}}/>
          <h1>{data.data.name}</h1>
          <Divider />
          <h3>
            {data.data.qualifications} | {data.data.qualifications}
          </h3>
          <p><div dangerouslySetInnerHTML={{__html: data.data.other_information }}></div></p>
          <Divider />
          <div className={classes.appoinmentbtndiv}>
            <a className={classes.appoinmentbtn} href="/appointment">
              <strong>Book An Appoinment</strong>
            </a>
          </div>
          {/* <div className={classes.appoinmentbtndiv}>
            <a className={classes.appoinmentbtn} href="https://wa.me/?text=I'm%20inquiring%20about%20the%20Appoinment" target="blank">
              <strong>Chat On Whatsapp</strong>
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Doctordetail;
