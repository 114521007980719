import React from "react";
import classes from "./Facilities.module.css";
import FacilitiesData from "./FacilitiesData";
import { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

function Facilitieslimit(props) {
  const [items, setItems] = useState(FacilitiesData);

  // Slice the first 3 facilities from the items array
  const displayedItems = items.slice(0, 3);

  return (
    <>

        <Grid container spacing={2}>
          {displayedItems.map((elem) => {
            const { id, title, image, description } = elem;

            return (
              <Grid item md={4} key={id}>
                <div className={classes.facilitiesItem}>
                  <img src={image} alt={title} className={classes.facilitiesimg} />
                  <h2>{title}</h2>
                  <p>{description}</p>
                  <p>
                    <Link to={`/facilities/${id}`} className={classes.facilitiesurl}>
                      Read More
                    </Link>
                  </p>
                </div>
              </Grid>
            );
          })}
        </Grid>

    </>
  );
}

export default Facilitieslimit;
