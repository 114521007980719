import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import classes from "./Home.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useQuery } from "react-query";
import MenuItem from '@mui/material/MenuItem';
import { MdPlayArrow } from "react-icons/md";
function Homesearch() {
    const navigate = useNavigate();

    const [departments, setDepartments] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState('');
    
    useEffect(() => {
        // Fetch department data
        fetch('https://mes.devleniko.com/api/departments')
            .then(response => response.json())
            .then(data => setDepartments(data.data))
            .catch(error => console.error('Error fetching departments:', error));
    
        // Fetch all doctors
        fetch('https://mes.devleniko.com/api/doctors')
            .then(response => response.json())
            .then(data => setDoctors(data.data))
            .catch(error => console.error('Error fetching doctors:', error));
    }, []);
    
    const handleDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
        setSelectedDoctor(''); // Reset selected doctor when department changes
    };
    
    const handleDoctorChange = (event) => {
        setSelectedDoctor(event.target.value);
    };
    
    const handleSearch = () => {
        // Use the selectedDepartment and selectedDoctor values for searching
        console.log('Searching for:', selectedDepartment, selectedDoctor, searchQuery);
    
        // Implement your logic for searching departments and doctors
        // For example, you can filter departments and doctors based on the search query
    
        // Redirect to the corresponding department or doctor profile page if found
        if (selectedDepartment) {
            const selectedDepartmentData = departments.find(department => department.id === selectedDepartment);
            if (selectedDepartmentData) {
                navigate(`/medical-team/`);
            }
        } else if (selectedDoctor) {
            const selectedDoctorData = doctors.find(doctor => doctor.id === selectedDoctor);
            if (selectedDoctorData) {
                navigate(`/doctors/${selectedDoctorData.slug}`);
            }
        }
    };
    
    // Filter doctors based on the selected department
    const filteredDoctors = selectedDepartment
        ? doctors.filter((doctor) => doctor.department_id === selectedDepartment)
        : doctors;
    

    return (
        <div className={classes.homebannersearch}>
            <Box
                component="form"
                sx={{
                    "& > :not(style)": { m: 1, width: "22ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <span>Specialities:</span>
                <TextField
                    select
                    name="deptmnt"
                    label="Select"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    variant="outlined"
                    fullWidth
                >
                    {departments.map((department) => (
                        <MenuItem key={department.id} value={department.id}>
                            {department.name}
                        </MenuItem>
                    ))}
                </TextField>

                <span>Find a Doctor:</span>
                <TextField
                    select
                    name="doctors"
                    label="Select"
                    value={selectedDoctor}
                    onChange={handleDoctorChange}
                    variant="outlined"
                    fullWidth
                >
                    {filteredDoctors.map((doctor) => (
                        <MenuItem key={doctor.id} value={doctor.id}>
                            {doctor.name}
                        </MenuItem>
                    ))}
                </TextField>



                <Button
                    className={classes.homesearchbtn}
                    variant="contained"
                    onClick={handleSearch}
                >
                    Search <MdPlayArrow />
                </Button>
            </Box>
        </div>
    )
}

export default Homesearch
