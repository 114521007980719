import React from "react";
import classes from "./Pharmacy.module.css";
import pharmacybannerimg from "../../assets/banner6.jpg";
import pharmacyimg from "../../assets/pharmacy.jpg";
import Grid from '@mui/material/Grid';

function Pharmacy() {
    return (
        <>
            <div
                className={classes.pharmacybanner}
                style={{ backgroundImage: `url(${pharmacybannerimg})` }}
            >
                <div className={classes.pharmacybanneroverlay}>
                    <div className={classes.pharmacybannercon}>
                        <h1>We are committed to excellence in service</h1>
                        <p>
                            <strong>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
            <div className={classes.pharmacybody}>
                <h1>Pharmacy</h1>

                <Grid container spacing={2} className={classes.pharmacygrid}>
                    <Grid item md={6}>
                        <img src={pharmacyimg} alt="pharmacyimg" width={'100%'} />
                    </Grid>
                    <Grid item md={6}>
                        <p>Step into our newly renovated Pharmacy, where a harmonious blend of aesthetics and functionality awaits.
                        Our contemporary design enhances your pharmaceutical experience, creating a welcoming atmosphere.
                        With expanded space and a well organized layout, we've optimized convenience for swift service.
                        Knowledgeable pharmacists stand ready to assist, ensuring a seamless and informed interaction.
                        Embrace the future of pharmaceutical care in a setting that prioritizes efficiency, comfort, and your well-being.
                        </p>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Pharmacy;
