import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import TipsData from "./TipsData";
import classes from "./Tips.module.css";
import tipsbannerimg from "../../assets/banner5.jpg";
import Divider from "@mui/material/Divider";
import { useQuery } from "react-query";

const TipsDetail = () => {
  const { slug } = useParams();

  useEffect(() => {
    console.log(slug);
  }, []);

  const { isLoading, error, data } = useQuery("healthDetails", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/health-tips/"+slug).then((res) =>
    res.json()
  )
);
if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;

  // const Tips = TipsData.find((tip) => tip.id === parseInt(id));

  // if (!Tips) {
  //   return <div style={{ margin: "50px" }}>No Data found.</div>;
  // }
  return (
    <>
      <div
        className={classes.tipsbanner}
        style={{ backgroundImage: `url(${tipsbannerimg})` }}
      >
        <div className={classes.tipsbanneroverlay}>
          <div className={classes.tipsbannercon}>
            <h1>{data.data.title}</h1>
            {/* <p>
              <strong>Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
              eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
              amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
              volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
              adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
              dolore ercit ati on ull amco laboris nisi ut aliqui.</strong>
            </p> */}
          </div>
        </div>
      </div>

      <div className={classes.tipsdetailbody}>
        <div className={classes.tipsdetailcon}>
          <img
            src={process.env.REACT_APP_PUBLIC_URL + "/" + data.data.image}
            alt={data.data.title}
            className={classes.tipsdetailimg}
          />
          <h1>{data.data.title}</h1>
          <p>{data.data.date.slice(0, 10)}</p>
          <p><div dangerouslySetInnerHTML={{__html: data.data.description }}></div></p>
        </div>
      </div>
    </>
  );
};

export default TipsDetail;
