import React, { useEffect } from "react";
import classes from "./Doctors.module.css";
import Docs from "./doctorsdata";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import docbannerimg from "../../assets/od.jpg";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "react-query";
import axios from "axios";

function Doctors(props) {
  const { isLoading, error, data } = useQuery("DoctorList", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/departments").then((res) =>
      res.json()
    )
  );

  const [items, setItems] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const departmentNames = [...new Set(Docs.map((doc) => doc.department))];

  const filterItem = (categDept, event, index) => {
    data.data.map((dep, index) => {
      var doctors = dep.doctors;
      if (dep.id === categDept) {
        setItems(dep.doctors);
        setSelectedIndex(index + 1);
      }

      if (categDept === 0) {
        getDept();
      }
    });
  };

  function getDept() {
    axios.get(process.env.REACT_APP_PUBLIC_API_URL + "/doctors")
      .then((response) => {
        const myRepo = response.data;
        setItems(myRepo.data,0);
        // console.log(myRepo);
      })
  }

  useEffect(() => {
    getDept()
  }, []);

  // const filterItem1 = (categDept) => {
  //   const updatedItems = Docs.filter((curElem) => {
  //     return curElem.department === categDept;
  //   });

  //   setItems(updatedItems);
  // };

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;
  // console.log("depts", data.data);

  return (
    <>
      <div
        className={classes.docbanner}
        style={{ backgroundImage: `url(${docbannerimg})` }}
      >
        <div >
          <div className={classes.docbannercon}>
        
            <p>{/* Your description goes here */}</p>
          </div>
        </div>
      </div>

      <div className={classes.docbody}>
        <Select
          className={classes.docdropdown}
          value={selectedIndex}
          onChange={(event) => {
            const index = event.target.value;
            setSelectedIndex(index);
            const selectedDeptId = index === 0 ? 0 : data.data[index - 1].id;
            filterItem(selectedDeptId, null, index);
          }}
        >
          <MenuItem value={0}>All</MenuItem>
          {data.data.map((deptName, index) => (
            <MenuItem key={index + 1} value={index + 1}>
              {deptName.name}
            </MenuItem>
          ))}
        </Select>

        <Grid container spacing={2}>
          <Grid item md={3}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              className={classes.doclistweb}
            >
              <ListItemButton
                key={0}
                selected={selectedIndex === 0}
                onClick={(event) => filterItem(0, event, 0)}
              >
                <ListItemText primary="All" />
              </ListItemButton>
              {data.data.map((deptName, index) => (
                <ListItemButton
                  key={index + 1}
                  selected={selectedIndex === index + 1}
                  onClick={(event) => filterItem(deptName.id, event, index + 1)}
                >
                  <ListItemText primary={deptName.name} />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              {items && items?.map((elem) => {
                const { id, name, slug, department } = elem;

                return (
                  <Grid item lg={4} sm={6} key={id}>
                    <div className={classes.docslist}>
                      <Card className={classes.docscard}>
                        <CardMedia
                          component="img"
                          alt={name}
                          image={process.env.REACT_APP_PUBLIC_URL + "/" + elem.image}
                          className={classes.docsimg}
                          style={{ "height": "404px" }}
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className={classes.docsname}
                          >
                            {name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {department}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Link to={`/doctors/${slug}`}>
                            <Button size="small">Learn More</Button>
                          </Link>
                        </CardActions>
                      </Card>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Doctors;
