import React from "react";
import { useState } from "react";
import classes from "./Career.module.css";
import careerbannerimg from "../../assets/Careers.jpg";
import CareerData from "./CareerData";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

function Career(props) {
  const { isLoading, error, data } = useQuery("career", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/careers").then((res) =>
      res.json()
    )
  );

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      <div
        className={classes.careerbanner}
        style={{ backgroundImage: `url(${careerbannerimg})` }}>
        <div >
          <div>
            <p>
              <strong></strong>
            </p>
          </div>
        </div>
      </div>

      <div className={classes.careerbody}>
        <h3>The current job openings are:</h3>
        <div className={classes.careerlist}>
          {data.data.map((item, i) => {
            return (
              <div className={classes.careerItem}>
                <h2>{item.title}</h2>
                <p>
                  <strong style={{ color: "#800020" }}>Department: </strong>{" "}
                  {item.departments.name}
                </p>
                <p>
                  <strong style={{ color: "#800020" }}>Qualification: </strong>
                  {item.qualification}
                </p>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                <p className={classes.careerlinkbox}>
                  <Link to={`/career/${item.id}`} className={classes.careerurl}>
                    Apply Now
                  </Link>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Career;
