import './App.css';
import Navigate from './navigation/Navigate';


function App() {
  return (
    <div className="App">
      <Navigate/>
    </div>
  );
}

export default App;