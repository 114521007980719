import React from "react";
import classes from "./Clinics.module.css";
import clinicsbannerimg from "../../assets/Ourcli.jpg";

function Clinics() {
    return (
        <>
            <div
                className={classes.clinicsbanner}
                style={{ backgroundImage: `url(${clinicsbannerimg})` }}
            >
                <div >
                    <div className={classes.clinicsbannercon}>
                      
                        <p>
                            <strong>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
            <div className={classes.clinicsbody}>
                <h2>MES Health Centre at Pandikkad, Malappuram District, Kerala.</h2>
                <p>
                    Pandikkad is a large village located in Ernad of Malappuram District, Kerala with total 3578 familites residing. 
                    This village has population of 18406 of which 8729 are males while 9677 are females as per population census 2011. 
                    In pandikkad village population of children with age of 0-6 is 2548 which makes up 13.87% of total population of the Village. 
                    Average Sex ration of Pandikkad village is 1109 which is higher than Kerala state average of 1084. 
                    Child Sex Ratio for the Pandikkad as per census is 966, higher than the state average of 964.
                    <br></br><br></br>
                    MES has started this centre in 2014 with the facilities of outpatient consultation for medical and dental treatment, with laboratory services and drug store. Later on, Pain & Palliative activities were started and a good number of patients are benefitted with home care visits. Now this centre caters its services to more than 35000 patients in this panchayat annually. Apart from this, this Centre utilizes all its resource for educating the public on various issues like Vaccination, prevention of diseases, family welfare activities etc. Recently, the Centre has started its evening Clinics in General Medicine, Obstetrics & Gynacology, Paediatrics, E.N.T., Orthopaedics etc. in order to reach its services to more people. Apart from these, weekly clinics for Geriatric Care, Mentally Challenged, Pain & Palliative care are also being conducted. This centre has introduced a novel health programme for school students in and around this village. All the students would be screened on their Health & Dental hygiene, Vision & Hearing, Mental & Physical stress etc. and health records would be prepared and submitted to the respective school for further management.
                </p>

                <h2>MES Health Centre at Pattikad, Vettathur Panchayath, Kerala.</h2>
                <p>
                    This centre was started in 2007 at Pattikad in Vettathur Panchayath as a Rural health Centre of MES Medical College. 
                    It is gramapanchayath in Malappuram District, having population of 31,335. Now, the centre has facilities for outpatient 
                    consultation for Medical & Dental treatment with Lab & Pharmacy facilities. The Entire treatment including consultation, 
                    laboratory investigations, Drugs, Vaccination is provided free of cost. Annually an average of 20,000 patients are availing 
                    its services. This centre has initiated and introduced a special programme called “ MISSIO MUKTHI “ in order to eradicate 
                    Vaccine preventable diseases like diphtheria, whooping cough, measles, and tetanus that are being reported from different 
                    parts of the district after a long gap. This programme was hailed by many organizations and NGOs and extended their unstinted 
                    support for its success. This Centre has also been conducting awareness programmes on all medical days for the public even 
                    since the inception of this centre. It also undertakes Cancer Screening campaign, Organising Child Clubs, programmes for 
                    Gender empowerment and development of Youth Human Resources.
                </p>

                <h2>Health Centre at Perinthalmanna Municipality</h2>
                <p>
                    MES started this centre as an Urban health of MES Medical College in 2004 itself. This Municipality has 87, 356 population. 
                    This Centre caters its services mainly to the people who resides outskirts of the Municipality. This centre is involved in 
                    conducting various studies among the women and using all its resource for the promotion of vaccination. This centre also has 
                    the services of Specialty & Super specialty doctors from MES Medical College every day.
                </p>

                <h2>Health Centre at Attappadi, Palakkad District</h2>
                <p>MES has recently started its new Health Centre at Attappadi, Palakkad District, one of the major tribal Centers in the state 
                    and also socially, educationally and economically the most backward village in the state. Although the area is known to be one 
                    of the tribal heartland in Kerala, major proportion of the population today is constituted by the settlers from other parts of 
                    Kerala and Tamilnadu. The area Dwells 3 tribal community – Mudugars, Irulars and Kurumbars. Of these Kurumbars is the most 
                    remote and leads a primitive life in comparison to others. The total population of all these tribal is around 30000. 
                    There are a total of 192 tribal hamlets or Ooru in the area.
                    <br></br><br></br>
                    This centre is set to provide specialist care to tribal people and adequate social workers have been appointed to assess 
                    the need of the tribal community and to distribute essential medicines at their doorsteps. Attapadi has always been in the 
                    lime light due to infant mortality among the Tribals.
                    <br></br><br></br>
                    Pregnant women and lactating mothers were found to be suffering from chronic malnutrition and Anemia. 
                    Premature delivery and low birth weight was seen as the main reason for the infant deaths in the area. 
                    The mothers who had premature delivery gave a history of raised blood pressure (pregnancy induced hypertension) and anemia. 
                    The necessary measures are being brought out to take care of pregnant women and tribal children. A comprehensive health 
                    education programme is being charted out to address all these issue by this Centre. Apart from the Health promotional 
                    activities conducted at the Centre, wide spread outreach programmes on various health issue are being organized in the 
                    tribal colonies.
                </p>

                <h2>Upcoming Centres</h2>
                <p>MES is also on verge of expanding the services of a Health Centres at Paravanna (undeveloped coastal area) of Malappuram 
                    District. Paravanna fisheries village is spread over three wards of Vettom Gram Panchayath. This centre will give more 
                    emphasis on skin related diseases as it is being a coastal area. A extensive awareness campaign would be organized among 
                    the fishermen giving more stress on filariasis, congenital diseases and vaccination. MES is also starting an another Health 
                    Centre at Feroke Grama panchayath at Calicut very soon. This centre will have the facilities for dialysis treatment apart 
                    from other routine activities.
                    <br></br><br></br>
                    MES proposes to expand similar network of Health Complex / Centres all over India to extend affordable Medicare care to 
                    the ill-fated poor living even at farthest nook of the country
                </p>
            </div>
        </>
    );
}

export default Clinics;
