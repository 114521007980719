import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classes from "./Gallery.module.css";
import Gallerybannerimg from "../../assets/banner6.jpg";
import GalleryData from "./GalleryData";
import Grid from "@mui/material/Grid";
import Images from "./Images";
import Videos from "./Videos";
import { useQuery } from "react-query";

function ImagesbyAlbum(props) {

    const { id } = useParams();


    const { isLoading, error, data } = useQuery("allImages", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/images-by-album/" + id).then((res) =>
      res.json()
    )
    );
    if (isLoading) return "Loading...";
    
    if (error) return "An error has occurred: " + error.message;

  return (
    <div className={classes.Galleryfullbody}>
      <div
        className={classes.Gallerybanner}
        style={{ backgroundImage: `url(${Gallerybannerimg})` }}
      >
        <div className={classes.Gallerybanneroverlay}>
          <div className={classes.Gallerybannercon}>
            <h1>Gallery</h1>
            <p>
              <strong></strong>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.Gallerybody}>
        <div className={classes.GalleryFilterbutton}>
        </div>
        <Grid container spacing={2}>
    {data.data.map((item,i) => (
      <Grid item md={4} key={i}>
        <div
          
          
          style={{ cursor: "pointer" }}
        >
<div className={classes.Galleryeach}>
            
              <img
                src={process.env.REACT_APP_PUBLIC_URL + "/" + item.image}
                alt={item.name}
                style={{ width: "100%" }}
              />
            
            
                    <p >{item.description}</p>
          </div>
        </div>
      </Grid>
    ))}
  </Grid>
      </div>

    </div>
  );
}

export default ImagesbyAlbum;
