import React from "react";
import classes from "./Medicine.module.css";
import gmedicinebanner from "../../../assets/banner1.jpg";
import Deptlist from "../Deptlist";
import Grid from "@mui/material/Grid";
import specialitypic from "../../../assets/gm.png";
import { BiRadioCircle } from "react-icons/bi";

function Generalmedicine() {
  return (
    <div className={classes.gmedicinebody}>
      <div
        className={classes.gmedicinebanner}
        style={{ backgroundImage: `url(${gmedicinebanner})` }}
      >
        <div className={classes.gmedicinebanneroverlay}>
          <div className={classes.gmedicinebannercon}>
            <h1>General Medicine</h1>
            <p>
              <strong>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese.Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute
                irur tu dolo end erit in volup tate velit ese.
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div>
        <Grid container spacing={2} className={classes.gmedicinespeciality}>
          <Grid item md={5}>
            <div className={classes.gmedicinespecialitycon}>
              <h1>Speciality</h1>
              <p>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute
                irur tu dolo end erit in volup tate velit ese.
              </p>
              <ul style={{listStyleType:"none"}} className={classes.gmedicinespecialitylist}>
                <li><BiRadioCircle className={classes.gmedicinespecialityicon}/> Qualified Doctors</li>
                <li><BiRadioCircle className={classes.gmedicinespecialityicon}/> Free like Home Services</li>
                <li><BiRadioCircle className={classes.gmedicinespecialityicon}/> 24x7 Emergency Services</li>
                <li><BiRadioCircle className={classes.gmedicinespecialityicon}/> Outdoor Checkup</li>
                <li><BiRadioCircle className={classes.gmedicinespecialityicon}/> General Medical</li>
                <li><BiRadioCircle className={classes.gmedicinespecialityicon}/> Easy and Affordable Billing</li>
              </ul>
            </div>
          </Grid>
          <Grid item md={7}>
            <div className={classes.gmedicinespecialityimgdiv}>
              <img
                className={classes.gmedicinespecialityimage}
                src={specialitypic}
                alt=""
              ></img>
              <h2>Lorem ipsum</h2>
              <p>Lorem ipsum</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Deptlist />
      </div>
    </div>
  );
}

export default Generalmedicine;
