import React from "react";
import { useState } from "react";
import Deptsdata from "./Deptdata";
import classes from "./Deptlist.module.css";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

function Depts(props) {
    const [items, setItems] = useState(Deptsdata);
  const { isLoading, error, data } = useQuery("dept", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/non-super-departments").then((res) =>
      res.json()
    )
  );

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;


  return (
    <div className={classes.deptlist}>
      {data.data.map((item, index) => {
        const { slug } = item;

        return (
       
          <div key={index} className={classes.deptnewlist}>
            <h3>{item.name}</h3>
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/" + item.logo} width={80} height={80}
              alt=""
              className={classes.deptfleximg}
            />
            <p className={classes.deptflexdes}><div dangerouslySetInnerHTML={{__html: item.description }}></div></p>
            <p>
              <Link
                to={`/department/${item.slug}`}
                className={classes.deptflexurl}
              >
                Read More
              </Link>
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default Depts;
