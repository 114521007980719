import React, { useState } from "react";
import PatientsData from "./PatientsData";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { BsArrowRight } from "react-icons/bs";
import { useQuery } from "react-query";
import classes from "./Patients.module.css";
import patientsbannerimg from "../../assets/abt.jpg";

function Patients() {
  const { isLoading, error, data } = useQuery("test", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/testimonials").then((res) =>
    res.json()
  )
);
const [items, setItems] = useState(data);
if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;
    return (
      <>
        <div
          className={classes.patientsbanner}
          style={{ backgroundImage: `url(${patientsbannerimg})` }}
        >
          <div className={classes.patientsbanneroverlay}>
            <div className={classes.patientsbannercon}>
              <h1>Testimonial</h1>
             
            </div>
          </div>
        </div>
  
        <div className={classes.blogcontainerStyle}>
        <Grid container spacing={2}>
        {
              data.data.map((item, i) => {
                return (
              <Grid item md={4} key={item.key} style={{ marginTop: '20px' }}>
                <Link
                  to={`/patients/${item.id}`}
                  className={classes.blogurl}
                >
                  <div className={classes.blogItem}>
                    <img
                      src={process.env.REACT_APP_PUBLIC_URL + "/" + item.image}
                      alt={item.image}
                      className={classes.blogimg}
                    />
                    <h2>{item.author_name}</h2>
                    <p>{item.message}</p>
                  </div>
                </Link>
              </Grid>
                )
              })
            }
        </Grid>
        </div>
      </>
    );

}

export default Patients;
