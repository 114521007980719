import React, { useEffect, useState } from "react";
import classes from "./Gallery.module.css";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
function Images(props) {
  const { isLoading, error, data } = useQuery("ImageData", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/gallery").then((res) =>
      res.json()
    )
  );
  const [items, setItems] = useState(data);
  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;
  return (
    <Grid container spacing={2}>
      {data.data.map((item, i) => (
        <Grid item md={4} key={i}>
          <div


            style={{ cursor: "pointer" }}
          >
            
              <div className={classes.Galleryeach}>
              <Link to={`/images-album/${item.id}`} className={classes.newsurl}><img src={process.env.REACT_APP_PUBLIC_URL + "/" + item.image} alt={item.title} style={{ width: "100%" }} /></Link>
                <p >{item.description}</p>
              </div>
              
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default Images

