import React from "react";
import classes from "./Ebrochure.module.css";
import brochurebannerimg from "../../assets/banner6.jpg";
import EbrochureData from "./EbrochureData";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";

function Ebrochure(props) {
  const [items, setItems] = useState(EbrochureData);
  const { isLoading, error, data } = useQuery("brochure", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/files").then((res) =>
    res.json()
  )
);

if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <div
        className={classes.brochurebanner}
        style={{ backgroundImage: `url(${brochurebannerimg})` }}
      >
        <div className={classes.brochurebanneroverlay}>
          <div className={classes.brochurebannercon}>
            <h1>E-brochure</h1>
            <p>
              {/* <strong>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute
                irur tu dolo end erit in volup tate velit ese.
              </strong> */}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.brochurebody}>
        <Grid container spacing={2}>
        {data.data.map((item,index) => {
            // const { id, title, image, pdf } = elem;

            return (
              <Grid item md={6} key={index} style={{ marginBottom: "30px" }}>
                <div className={classes.brochurelist}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <img
                        src={process.env.REACT_APP_PUBLIC_URL + "/" + item.image}
                        alt={item.title}
                        className={classes.blogimg}
                        style={{ width: "50%" }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <h3>{item.name}</h3>
                      <a href={process.env.REACT_APP_PUBLIC_URL +"/uploads/brochure"+ "/" + item.brochure} target="_blank">
                        Download Pdf
                      </a>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
}

export default Ebrochure;
