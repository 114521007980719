import React from "react";
import { useState } from "react";
import classes from "./Insurance.module.css";
import InsuranceData from "./Insurancedata";
import insurancebannerimg from "../../assets/insur.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import VidalHealth from "../../assets/logos/VidalHealth.png"
import StarHealth from "../../assets/logos/StarHealth.png"
import Mediassist from "../../assets/logos/Mediassist.png"
import FutureGenerali from "../../assets/logos/FutureGenerali.png" 
import ParamountHealth from "../../assets/logos/ParamountHealth.png"
import AdityaBirla from "../../assets/logos/AdityaBirla.png"
import RakshaHealth from "../../assets/logos/RakshaHealth.png"
import FHPL from "../../assets/logos/FHPL.png";
import Liberty from "../../assets/logos/Liberty.png";
import Healthindia from "../../assets/logos/Healthindia.png";
import Cholamandalam from "../../assets/logos/Cholamandalam.png";
import CareHealth from "../../assets/logos/CareHealth.png";
import ICICI from "../../assets/logos/ICICI.png"
import TATA from "../../assets/logos/TATA.png"
import IFFCO from "../../assets/logos/IFFCO.png"
import Digit from "../../assets/logos/Digit.png"
import Ericson from "../../assets/logos/Ericson.png"
import SBI from "../../assets/logos/SBI.png"
import Heritage from "../../assets/logos/Heritage.png"
import HDFC from "../../assets/logos/HDFC.png"
import bajaj from "../../assets/logos/bajaj.png"
import Reliance from "../../assets/logos/Reliance.png"
import Sompo from "../../assets/logos/Sompo.jpg"
export default function Insurance(props) {
  const [items, setItems] = useState(InsuranceData);

  return (
    <>
      <div
        className={classes.insurancebanner}
        style={{ backgroundImage: `url(${insurancebannerimg})` }}
      >
        <div >
          <div className={classes.insurancebannercon}>
            {/* <h1>Insurance</h1> */}
          </div>
        </div>
      </div>
      <div className={classes.insurancebody}>
        {/* <p>
          In our Medical College, we have started Post Graduate Degree courses
          in Broad Specialties in the disciplines of General Medicine,
          Paediatrics, Pulmonology, Psychiatry, General and Laparoscopic
          Surgery, Orthopaedics, Obstetrics & Gynaecology,Ophthalmology,
          Anaesthesiology & Critical Care and Radio Diagnosis & Sonology. The
          post Graduate Doctors who undergo these courses are also serving in
          our Hospital in addition to our full-fledged faculty, Consultants and
          Medical Officers and therefore we are able to ensure that each and
          every patient here gets full time individual care of doctors.
        </p>
        <p>
          MES Medical college has eminent Professors, highly experienced faculty
          and Consultants from various prestigious medical colleges of different
          parts of the country with vast professional experience and expertise
          in their respective fields. We also extend free services in the
          Inpatient, Outpatient and Surgical Departments to poor and needy.
        </p> */}
        <h1>List Of Insurance & Companies</h1>
        <div >
          {/* {items.map((elem) => {
            const { title, description, url } = elem;

            return (
              <Accordion className={classes.insuranceaccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.insurancetitle}>
                    {title}
                  </Typography>
                </AccordionSummary>
               
              </Accordion>
            );
          })} */}
          <Clints/>
        </div>
      </div>
      <div>
      
      </div>
    </>
  );
}


const Clints = () => {
  return (
    <>
     
      
      <Grid container spacing={2}>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={VidalHealth}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={StarHealth}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Mediassist}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={FutureGenerali}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={ParamountHealth}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={AdityaBirla}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={RakshaHealth}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={FHPL}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Liberty}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Healthindia}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Cholamandalam}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={CareHealth}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={ICICI}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={TATA}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={IFFCO}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Digit}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Ericson}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={SBI}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Heritage}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={HDFC}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={bajaj}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Reliance}
                     
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
      <Grid item xs={4} md={3} className={classes.borders}>
        <div className={classes.border}>
        <img
                        src={Sompo}
                        alt=""
                        className={classes.w100}
                      />
        </div>
     
      </Grid>
</Grid>
      
    </>
  )}