import facilitiesimg from "../../assets/Mammogram.jpg";
import facilitiesimg1 from "../../assets/cathlab.jpg";
import facilitiesimg2 from "../../assets/MultidisciplinaryICUs.jpg";
import facilitiesimg3 from "../../assets/HighDependencyUnits.jpg";
import facilitiesimg4 from "../../assets/LargestModerndialysisunit.jpg";
import facilitiesimg5 from "../../assets/Endoscopy.jpg";


const FacilitiesData = [
  {
    id: 111,
    title: "Mammogram ",
    image: facilitiesimg,
    description: [
      "Experience excellence in women's healthcare at our hospital, where our mammogram facility is committed to providing accessible, efficient.",
    ],
    facilitieskey: [
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
    ],
  },
  {
    id: 112,
    title: "Cath Lab ",
    image: facilitiesimg1,
    description: [
      "Trust our expert team of cardiologists and skilled technicians in our advanced Cath Lab, where we prioritize your heart health through innovative.",
    ],
    facilitieskey: [
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
    ],
  },
  {
    id: 113,
    title: "Multidisciplinary ICUs",
    image: facilitiesimg2,
    description: [
      "Our hospital's Multidisciplinary ICUs are designed to offer comprehensive care, integrating the expertise of various medical disciplines.",
      
    ],
    facilitieskey: [
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
    ],
  },
  {
    id: 114,
    title: "High Dependency Units",
    image: facilitiesimg3,
    description: [
      "Discover a compassionate and attentive environment in our High Dependency Units, where our skilled medical staff provides.",
     
    ],
    facilitieskey: [
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      ],
  },
  {
    id: 115,
    title: "Largest Modern dialysis unit",
    image: facilitiesimg4,
    description: [
      "Experience the future of renal care in our hospital's Dialysis Unit, the largest and most modern facility, offering a comprehensive.",
    ],
    facilitieskey: [
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      ],
  },
  {
    id: 116,
    title: "Endoscopy",
    image: facilitiesimg5,
    description: [
      "Choose excellence in gastrointestinal care at our hospital's Endoscopy Facility, equipped with modern endoscopic technology and staffed.",
    ],
    facilitieskey: [
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
        "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
      ],
  }
  // ,
  // {
  //   id: 117,
  //   title: "Colonoscopy",
  //   image: facilitiesimg,
  //   description: [
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //   ],
  //   facilitieskey: [
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //     ],
  // },
  // {
  //   id: 118,
  //   title: "Bronchoscopy",
  //   image: facilitiesimg1,
  //   description: [
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //   ],
  //   facilitieskey: [
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //     ],
  // },
  // {
  //   id: 119,
  //   title: "1.5 Tesla MRI",
  //   image: facilitiesimg,
  //   description: [
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //   ],
  //   facilitieskey: [
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //     ],
  // },
  // {
  //   id: 120,
  //   title: "16 slice CT",
  //   image: facilitiesimg1,
  //   description: [
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //   ],
  //   facilitieskey: [
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //     ],
  // },
  // {
  //   id: 121,
  //   title: "Ultra Sound",
  //   image: facilitiesimg,
  //   description: [
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //   ],
  //   facilitieskey: [
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //     ],
  // },
  // {
  //   id: 122,
  //   title: "Physiotherapy unit",
  //   image: facilitiesimg1,
  //   description: [
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //   ],
  //   facilitieskey: [
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //     ],
  // },
  // {
  //   id: 123,
  //   title: "24 Hours Blood Bank",
  //   image: facilitiesimg,
  //   description: [
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //   ],
  //   facilitieskey: [
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //     ],
  // },
  // {
  //   id: 124,
  //   title: "Separate Inborn and out born Neonatology Intensive care unit",
  //   image: facilitiesimg1,
  //   description: [
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //     "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. ",
  //   ],
  //   facilitieskey: [
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //       "Assessment of Intelligence - To understand the level of intellectual functioning, we assess the intellectual ability of the person with standardized testing procedures.",
  //     ],
  // },
];

export default FacilitiesData;
