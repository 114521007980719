import adminimg from "../../assets/doctor.jpg";
import adminimg1 from "../../assets/doctor1.jpg";
import adminimg2 from "../../assets/doctor2.jpg";

const Admins = [
  {
    id: 20,
    name: "Dr. Administration Member",
    image: adminimg,
    designation: "Administration Team - Designation",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 21,
    name: "Dr. Administration Member1",
    image: adminimg2,
    designation: "Administration Team - Designation",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 22,
    name: "Dr. Administration Member2",
    image: adminimg1,
    designation: "Administration Team - Designation",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 23,
    name: "Dr. Administration Member3",
    image: adminimg,
    designation: "Administration Team - Designation",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 24,
    name: "Dr. Administration Member4",
    image: adminimg2,
    designation: "Administration Team - Designation",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
];

export default Admins;
