import React,{useEffect} from "react";
import { useParams } from "react-router-dom";
import PatientsData from "./PatientsData";
import classes from "./Patients.module.css";
import patientsbannerimg from "../../assets/abt.jpg";
import Divider from "@mui/material/Divider";
import { useQuery } from "react-query";

const PatientDetail = () => {
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
  }, []);

  const { isLoading, error, data } = useQuery("testDetails", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/testimonials/"+id).then((res) =>
    res.json()
  )
);
if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;

  // const Blogs = BlogData.find((blog) => blog.id === parseInt(id));

  // if (!Blogs) {
  //   return <div style={{ margin: "50px" }}>No Data not found.</div>;
  // }
  return (
    <>
    <div
          className={classes.patientsbanner}
          style={{ backgroundImage: `url(${patientsbannerimg})` }}
        >
          <div className={classes.patientsbanneroverlay}>
            <div className={classes.patientsbannercon}>
              <h1>Testimonial</h1>
             
            </div>
          </div>
        </div>
     

      <div className={classes.blogdetailbody}>
        <div className={classes.blogdetailcon}>
          <img
            src={process.env.REACT_APP_PUBLIC_URL + "/" + data.data.image}
            alt={data.data.author_name}
            className={classes.blogdetailimg}
          />
          <h1>{data.data.author_name}</h1>
          <p>{data.data.date.slice(0, 10)}</p>
          <p><div dangerouslySetInnerHTML={{__html: data.data.message }}></div></p>
        </div>
      </div>
    </>
  );
};

export default PatientDetail;
