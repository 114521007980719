import React, { useEffect, useState } from "react";
import classes from "./Home.module.css";
import FacilitiesData from "../facilities/FacilitiesData";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { BsArrowRight } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
function Facilitieshome(props) {
    const [items, setItems] = useState(FacilitiesData.slice(0, 6));
    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
        AOS.refresh();
      });
    return (
        <>
            <div className={classes.Facilitieshomecontainer}  data-aos="fade-up">
                <h1>Touching lives with caring hands</h1>
                <p>
                We, at MES Hospital, strive to offer the best care and a positive healthcare journey. 
                With specialized doctors and state-of-the-art technology, we bring international standards of medical care to our patients.
                </p>
                <p className={classes.viewbtn}>
                    <Link to="/facilities">View All &nbsp;<BsArrowRight /></Link>
                </p>
                <Grid container spacing={2}>
                    {items.map((elem) => {
                        const { id, title, image, description } = elem;

                        return (
                            <Grid item xs={12} md={4} key={id} style={{ marginTop: '20px' }}  data-aos="fade-up">
                                <Link
                                    to="#"
                                    className={classes.blogurl}
                                >
                                    <div className={classes.blr}>
                                    <div
                                        className={classes.facilitiesimg}
                                        style={{ backgroundImage: `url(${image})` }}
                                    >
                                            <div className={classes.facilitiescon}>
                                                <h3>{title}</h3>
                                                <p>{description}</p>
                                            </div>
                                            <div className={classes.dcgcontents}>{title}</div>
                                            <div className={classes.dcg}> <p>{description}</p></div>
                                            
                                    <div className={classes.dcgoverlays}></div>
                                    </div>
                                    
                                    </div>
                                   
                                </Link>
                               
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </>
    );
}

export default Facilitieshome;
