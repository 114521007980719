import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { TbGridDots } from "react-icons/tb";
import { IoCloseSharp } from "react-icons/io5";


export default function Sidebar() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className='sidedrawer'
    >
      <Button onClick={toggleDrawer(anchor, false)} className='closeicon'><IoCloseSharp /></Button>
      <List>
        {[
          { text: 'Home', link: '/' },
          { text: 'About Us', link: '/about' },
          { text: 'Our Doctors', link: '/medical-team' },
          { text: 'Departments', link: '/department' },
          { text: 'Health Packages ', link: '/health-checkup-packages' },
          { text: 'Facilities', link: '/facilities' },
          
      
          { text: 'Our Clinics', link: '/clinics' },
          { text: 'News & Events', link: '/news-events' },
          { text: 'Gallery', link: '/gallery' },
     
          // { text: 'Our Doctors', link: '/medical-team' },
          // { text: 'Blog', link: '/blog' },
          // { text: 'Faq', link: '/faq' },
          { text: 'Contact', link: '/contact' },
          { text: 'Blog', link: '/blog' },
         
        ].map(({ text, link }, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={link}>
              <ListItemText primary={text} className='sidemenus' />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  

  return (
    <div>
      <Button onClick={toggleDrawer('right', true)} className='sidebaricon'><TbGridDots /></Button>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
      >
        {list('right')}
      </Drawer>
    </div>
  );
}
