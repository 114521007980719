import React from "react";
import classes from "./Blog.module.css";
import blogbannerimg from "../../assets/abt.jpg";
import BlogData from "./BlogData";
import { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";

function Blog(props) {
  const [items, setItems] = useState(BlogData);
  const { isLoading, error, data } = useQuery("blog", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/blogs").then((res) =>
    res.json()
  )
);

if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <div
        className={classes.blogbanner}
        style={{ backgroundImage: `url(${blogbannerimg})` }}
      >
        <div className={classes.blogbanneroverlay}>
          <div className={classes.blogbannercon}>
            <h1>Blogs</h1>
            {/* <p>
              <strong>Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
              eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
              amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
              volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
              adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
              dolore ercit ati on ull amco laboris nisi ut aliqui.</strong>
            </p> */}
          </div>
        </div>
      </div>

      <div className={classes.blogcontainerStyle}>
        <Grid container spacing={2}>
          {data.data.map((item,index) => {
            // const { id, title, image, description, date } = elem;

            return (
              <Grid item md={4} key={index}>
                <div className={classes.blogItem}>
                  <img
                    src={process.env.REACT_APP_PUBLIC_URL + "/" + item.image}
                    alt={item.title}
                    className={classes.blogimg}
                  />
                  <h2>{item.title}</h2>
                  <p>{item.blog_date.slice(0, 10)}</p>
                  <p>{item.description}</p>
                  <p>
                    <Link
                      to={`/blog/${item.slug}`}
                      className={classes.blogurl}
                    >
                      Read More
                    </Link>
                    </p>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
}

export default Blog;
