import React from "react";
import classes from "./SpecialSchemes.module.css";
import schemesbannerimg from "../../assets/banner3.jpg";
import Grid from "@mui/material/Grid";

const SchemeList = [
  {
    id: 1,
    title: "Special Schemes 1",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 2,
    title: "Special Schemes 2",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 3,
    title: "Special Schemes 3",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
  {
    id: 4,
    title: "Special Schemes 4",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
  },
];

function SpecialSchemes(props) {
  return (
    <>
      <div
        className={classes.schemesbanner}
        style={{ backgroundImage: `url(${schemesbannerimg})` }}
      >
        <div className={classes.schemesbanneroverlay}>
          <div className={classes.schemesbannercon}>
            <h1>Special Schemes</h1>
            <p>
              <strong>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui.
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.schemesbody}>
        {SchemeList.map((item) => (
          <div key={item.id}>
            <Grid container spacing={2} className={classes.schemeslist}>
              <Grid item xs={12}>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </>
  );
}

export default SpecialSchemes;
