import axios from "axios";

const index = () => {
    const data = axios.get(process.env.REACT_APP_PUBLIC_API_URL + `/departments`);
    return data;
};

const show = slug => {
    const data = axios.get(process.env.REACT_APP_PUBLIC_API_URL + `/departments/` + slug);
    return data;
};

const showById = id => {
    const data = axios.get(
        process.env.REACT_APP_PUBLIC_API_URL + `/departments-by-id/` + id
    );
    return data;
};

export default {
    index,
    show,
    showById,
};