import React from "react";
import classes from "./Facilities.module.css";
import facilitiesbannerimg from "../../assets/Fslt.jpg";
import FacilitiesData from "./FacilitiesData";
import { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Facilitieslimit from "./facilitieslimit";

function Facilities(props) {
  const [items, setItems] = useState(FacilitiesData);
  return (
    <>
      <div
        className={classes.facilitiesbanner}
        style={{ backgroundImage: `url(${facilitiesbannerimg})` }}
      >
        <div >
          <div className={classes.facilitiesbannercon}>
            {/* <h1>Facilities</h1> */}
           
          </div>
        </div>
      </div>

      <div className={classes.facilitiescontainerStyle}>
        <Grid container spacing={2}>
          {items.map((elem) => {
            const { id, title, image, description } = elem;

            return (
              <Grid item md={4} key={id} style={{marginBottom: '30px'}}>
                <div className={classes.facilitiesItem}>
                  <img src={image} alt={title} className={classes.facilitiesimg} />
                  <h2>{title}</h2>
                  <p>{description}</p>
                  <p>
                    {/* <Link to={`/facilities/${id}`} className={classes.facilitiesurl}>
                      Read More
                    </Link> */}
                  </p>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
}

export default Facilities;
