import Deptimg from "../../assets/Gynaecology.svg"
import Deptimg1 from "../../assets/Pulmonology.svg"
import Deptimg2 from "../../assets/GeneralMedicine.svg"
import Deptimg3 from "../../assets/Cosmetology.svg"
import Featureimg from "../../assets/dept.jpg"
import Featureimg1 from "../../assets/dept1.jpg"
import Featureimg2 from "../../assets/dept2.jpg"
import Featureimg3 from "../../assets/dept3.jpg"
import Featureimg4 from "../../assets/dept4.jpg"



const Deptsdata = [
    {
        id: 1,
        title: "Gynaecology, Obstetrics and Infertility",
        image: Deptimg,
        featureimg: Featureimg,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 2,
        title: "Department of Pulmonology",
        image: Deptimg1,
        featureimg: Featureimg1,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 3,
        title: "Department of General Medicine",
        image: Deptimg2,
        featureimg: Featureimg2,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 4,
        title: "Gynaecology, Dermatology, Venerology & Cosmetology",
        image: Deptimg3,
        featureimg: Featureimg3,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 5,
        title: "Otology",
        image: Deptimg,
        featureimg: Featureimg4,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 6,
        title: "Pulmonology",
        image: Deptimg1,
        featureimg: Featureimg2,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 7,
        title: "Urology",
        image: Deptimg,
        featureimg: Featureimg,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 8,
        title: "Dental Care",
        image: Deptimg1,
        featureimg: Featureimg3,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 9,
        title: "Orthopedics",
        image: Deptimg,
        featureimg: Featureimg1,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
      {
        id: 10,
        title: "Eye Care",
        image: Deptimg1,
        featureimg: Featureimg4,
        description: 'Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.',
        features: [
          "Qualified Doctors",
          "Free like Home Services",
          "24x7 Emergency Services",
          "Outdoor Checkup",
          "General Medical",
          "Easy and Affordable Billing"
        ],
      },
];

export default Deptsdata;