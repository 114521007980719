import React, { useEffect, useState } from "react";
import classes from "./Home.module.css";
import PatientsData from "../patients/PatientsData";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { BsArrowRight } from "react-icons/bs";
import { useQuery } from 'react-query'
import AOS from "aos";
import "aos/dist/aos.css";
function Patientshome(props) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  });
  const [items, setItems] = useState(PatientsData.slice(0, 3)); 

  const { isLoading, error, data } = useQuery('testimonial_data', () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + '/testimonials-limit').then(res =>
    res.json()
  )
)

if (isLoading) return 'Loading...'


if (error) return 'An error has occurred: ' + error.message


  return (
    <>
      <div className={classes.blogcontainerStyle} data-aos="fade-up">
        <h1>Our Patients Stories</h1>
        <p>
        Take a look at the feedback we're receiving.<br></br>
        Our patients, our biggest supporters, share their inspiring stories about their treatment experiences.
        </p>
        <Grid container spacing={2}>
        {
              data.data.map((item, i) => {
                return (
              <Grid item md={4} key={item.key} style={{ marginTop: '20px' }} data-aos="fade-up">
                <Link
                  to={`/patients/${item.id}`}
                  className={classes.blogurl}
                >
                  <div className={classes.blogItem}>
                    <img
                      src={process.env.REACT_APP_PUBLIC_URL + "/" + item.image}
                      alt={item.image}
                      className={classes.blogimg}
                    />
                    <h2>{item.author_name}</h2>
                    <p>{item.message}</p>
                  </div>
                </Link>
              </Grid>
                )
              })
            }
        </Grid>
        <p className={classes.viewbtn}>
          <Link to="/patients">View All &nbsp;<BsArrowRight /></Link>
        </p>
      </div>
    </>
  );
}

export default Patientshome;
