

import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import classes from "./About.module.css";
import aboutbannerimg from "../../assets/abs.jpg";
import aboutbannerimgs from "../../assets/DJI_0183.JPG";
import docimg1 from "../../assets/photo2.png"

import docimg2 from "../../assets/photo3.png"
import docimg3 from "../../assets/photo1.png"
import docimg4 from "../../assets/photo5.png"
import docimg5 from "../../assets/photo4.png"
import Facilitieslimit from "../facilities/facilitieslimit";
import { Link } from "react-router-dom";
import Certifications from "./Certifications";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
   
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
    </>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        className={classes.aboutbanner}
        style={{ backgroundImage: `url(${aboutbannerimg})` }}
      >
        {/* <div className={classes.aboutbanneroverlay}>
          <div className={classes.aboutbannercon}>
            <h1>About Us</h1>
            <p>
              <strong></strong>
            </p>
          </div>
        </div> */}
      </div>
      <div className={classes.aboutusbody}>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Our Story" {...a11yProps(0)} />
          <Tab label="Mission and Vision" {...a11yProps(1)} />
          <Tab label="Management Team" {...a11yProps(2)} />
          <Tab label="Milestones" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Grid container spacing={2} className={classes.aboutusgrid}>
          <Grid item md={6} className={classes.aboutuscon}>
            <h1>MES Hospital</h1>
            <p>
              <strong>MES Medical College Hospital</strong> is a 600-bedded
              multi-super specialty hospital established in 2003 to provide a
              complete range of diagnostic and treatment services. The Campus is
              spread over 60 acres of land and also houses MES Academy of
              Medical Sciences with centers of excellence in Dentistry, Nursing
              and Para Medical Sciences.
            </p>
            <p>
              Equipped with world-class technology, MES MCH is set to match the
              highest global standards of healthcare. Backed by respected and
              leading medical experts, the hospital is committed to provide the
              right medical care to each patient who entrusts their lives in its
              care.
            </p>
            <p>
              MES MCH, now in its 17th year of service has transformed the
              Healthcare scenario in the Malabar region. This has been achieved
              by maintaining high standards in clinical care using the latest
              diagnostic and treatment facilities available.
            </p>
          </Grid>
          <Grid item md={6}>
            <img
              className={classes.aboutusimage}
              src={aboutbannerimgs}
              alt=""
            ></img>
          </Grid>
          <Grid item xs={12}>
            <p>
              In our Medical College, we have started Post Graduate Degree
              courses in Broad Specialties in the disciplines of General
              Medicine, Paediatrics, Pulmonology, Psychiatry, General and
              Laparoscopic Surgery, Orthopaedics, Obstetrics &
              Gynaecology,Ophthalmology, Anaesthesiology & Critical Care and
              Radio Diagnosis & Sonology. The post Graduate Doctors who undergo
              these courses are also serving in our Hospital in addition to our
              full-fledged faculty, Consultants and Medical Officers and
              therefore we are able to ensure that each and every patient here
              gets full time individual care of doctors.
            </p>
            <p>
              MES Medical college has eminent Professors, highly experienced
              faculty and Consultants from various prestigious medical colleges
              of different parts of the country with vast professional
              experience and expertise in their respective fields. We also
              extend free services in the Inpatient, Outpatient and Surgical
              Departments to poor and needy.
            </p>
          </Grid>
        </Grid>
        
        <div className={classes.aboutuswhyus}>
          <h1>Why Us</h1>
          <p>
            MES Medical College Hospital is a 600-bedded multi-super specialty
            hospital established in 2003 committed to deliver top quality
            patient care to the needies. We are determined to provide excellent
            health care at affordable cost to our patients with our highly
            experienced health care professionals. Our aim is to develop and
            maintain an unrivalled clinical expertise to provide quality
            treatment with excellent service by the dedicated team of
            experienced hands.
          </p>
        </div>
       

        {/* <div className={classes.aboutuscertification}>
          <h1>Certified By</h1>
          <Certifications />
        </div> */}

        <div className={classes.aboutusfacilities}>
          <h1>Our Facilities</h1>
          <Facilitieslimit />
          <h4>
            <Link to={`/facilities`} className={classes.facilitiesurl}>
              See Our All Facilities
            </Link>
          </h4>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Grid container >
      <Grid item xs={12} className={classes.missioncon}>
        
            <h3>Our Vision</h3>
            <p>
              The MES Medical College is the end of an arduous trek of a once
              beleaguered community from the womb of ignorance, illiteracy and
              ignominy. Leaving aside the horrors of 1921, the partition of
              minds in 1947, we hold our hands with the brethren of our
              fraternal communities and march forward into a new dawn of
              scientific temper and secular ethos.
              <br></br>
              <br></br>
              Our vanguard is the great teachers who have taught us the timeless values of knowledge, love and compassion. For these achieve­ ments, we shower our thanksgiving only to the Master of the Universe God Almighty




              <br></br>
              <br></br>
              Our inspiration is the great helmsman, Dr. PK Abdul Ghafoor and his band of community leaders who inspired us to success From the portals of this great institution Let sally forth healers, leaders, teachers and philanthropists of this community and nation Let this institution mother, foster and nurture many in the service of mankind May the future generations view this seat of learning as the Academy, the lyceum and the Cordova of enlightenment Let us recognize our common blood, break our shackles, and bind our wounds and build a new heritage from here to eternity
             
            </p>
            </Grid>  
        </Grid>
        <Grid item xs={12} className={classes.missioncon}>
            <h3>Our Mission</h3>
            <p>
            The mission of MES Medical College Hospital is to make available the highest quality of health services and facilities for the community, to give wellness to all, to relieve pain and restore health as speedily and safely and as humanely as is possible regardless of age, gender or creed with competent and innovative services as well as with human care and respect and educate the people on prevention of illness and thus help them be healthy always
            </p>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {/* <img src="muhammedsajid1.jpg" class="rounded-circle" alt="Cinque Terre" width="304" height="236"/>  */}
      <Grid container className={classes.textcenter}>
     
           <Grid item xs={4} className={classes.textcenter}>
      <img
              className={classes.docimage}
              src={docimg2}
              alt=""
            >

            </img>
            <h4 className={classes.textcenters}>FOUNDER</h4>
            
            <p className={classes.textcenter}>Dr.P.K Abdul Gafoor</p>
           </Grid>
           <Grid item xs={4} className='mt-5'>
      <img
              className={classes.docimage}
              src={docimg1}
              alt=""
            ></img>
              <h4 className={classes.textcenters}>DIRECTOR</h4>
            
            <p className={classes.textcenter}>Dr.PA.Fazal Ghafoor MD,DM</p>
           </Grid>
           </Grid><br></br>
           <Grid container className={classes.textcenter}>
           <Grid item xs={4} className='mt-5'>
      <img
              className={classes.docimage}
              src={docimg4}
              alt=""
            ></img>
             <h4 className={classes.textcenters}>DEAN</h4>
            
            <p className={classes.textcenter}>Dr. Girish Raj</p>
           </Grid>
          
           <Grid item xs={4} className='mt-5'>
      <img
              className={classes.docimage}
              src={docimg5}
              alt=""
            ></img>
              <h4 className={classes.textcenters}>MEDICAL SUPERINTENDENT</h4>
            
            <p className={classes.textcenter}>Dr. Muhammad Sajid AM</p>
           </Grid>
           <Grid item xs={4} className='mt-5'>
      <img
              className={classes.docimage}
              src={docimg3}
              alt=""
            ></img>
              <h4 className={classes.textcenters}>DEPUTY MEDICAL SUPERINTENDENT</h4>
            
            <p className={classes.textcenter}>Dr.Hameed fazal</p>
           </Grid>
           </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <h1>“Turning dreams into milestones”</h1>
        <p><strong>2002</strong>  Foundation stone was laid by Dr. P.A.Fazal Ghafoor and his team</p>
        <p><strong>2003</strong>  Established MES medical college hospital with 330 beds</p>
        <p><strong>2004</strong>  First MBBS batch was started</p> 
        <p><strong>2004</strong>  Established MES college of nursing</p> 
        <p><strong>2006</strong>  Started Super Specialty departments</p> 
        <p><strong>2007</strong>  Established MES Dental college</p>
        <p><strong>2009 </strong> Inaugurated advanced Cath lab and mammogram</p>
        <p><strong>2010 </strong> started MES institute of paramedical college</p>
        <p><strong>2011 </strong>  MES medical college started postgraduate programs</p> 
        <p><strong>2011 </strong> MES institute of paramedical science started BSc MLT</p> 
        <p><strong>2012 </strong> The installation of the first MRI Scanner</p>
        <p><strong>2012 </strong> MES institute of paramedical science started diploma courses</p>
        <p><strong>2015 </strong> Started 24 hours radiology services</p> 
        <p><strong>2016 </strong> MES dental and nursing college started postgraduate courses</p>
        <p><strong>2017 </strong> Started Arogyavaaram</p>
        <p><strong>2019 </strong> KASP empanelled</p>
        <p><strong>2020 </strong> Opened first private COVID hospital in Malappuram district</p>
        <p><strong>2023 </strong> Inauguration of newly renovated orthopedic ward</p> 
        <p><strong>2023 </strong> MES institute of paramedical science started BSc neuro technology course</p>
        <p><strong>2023 </strong> Inaugurated renovated pharmacy</p>  
        <p><strong>2023 </strong> Launched karuthal health scheme</p>
        

        </CustomTabPanel>
    </Box>
    </div>
    </>
  );
}
