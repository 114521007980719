import React from "react";
import classes from "./Administration.module.css";
import Admins from "./AdministrationData";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import adminbannerimg from "../../assets/banner6.jpg";

function Administration(props) {
  const [items, setItems] = useState(Admins);
  return (
    <>
      <div
        className={classes.adminbanner}
        style={{ backgroundImage: `url(${adminbannerimg})` }}
      >
        <div className={classes.adminbanneroverlay}>
          <div className={classes.adminbannercon}>
            <h1>Administration Team</h1>
            <p>
              <strong>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute
                irur tu dolo end erit in volup tate velit ese.
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.adminbody}>
        {items.map((elem) => {
          const { id, name, designation, description, image } = elem;
          return (
            <Grid container spacing={2} className={classes.adminitem}>
              <Grid item md={3}>
                 <div className={classes.adminimg}>
                 <img src={image} alt={name} />
                 </div>
              </Grid>
              <Grid item md={9}>
                 <div className={classes.admincont}>
                    <h3>{designation}</h3>
                    <h1>{name}</h1>
                    <p>{description}</p>
                 </div>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </>
  );
}

export default Administration;
