import React from "react";
import { useParams } from "react-router-dom";
import classes from "./Facilities.module.css";
import FacilitiesData from "./FacilitiesData";
import { BiRadioCircle } from "react-icons/bi";

function Facilitiesdetail(props) {
  const { id } = useParams();

  const facility = FacilitiesData.find(
    (facility) => facility.id === parseInt(id)
  );

  if (!facility) {
    return <div style={{ margin: "50px" }}>Data not found.</div>;
  }

  const descriptionColumns = {
    columnCount: 2,
    columnGap: "40px", // Adjust the gap between columns as needed
  };

  return (
    <>
      <div
        className={classes.facilitiesbanner}
        style={{ backgroundImage: `url(${facility.image})` }}
      >
        <div className={classes.facilitiesbanneroverlay}>
          <div className={classes.facilitiesbannercon}>
            <h1>{facility.title}</h1>
          </div>
        </div>
      </div>

      <div className={classes.facilitydetailbody}>
        <div className={classes.facilitydetailcon}>
          {facility.description.map((paragraph, index) => (
            <p key={index}>
              {paragraph}
            </p>
          ))}
          <ul
            style={{ listStyleType: "none" }}
            className={classes.facilitydetaillist}
          >
            {facility.facilitieskey.map((facility, index) => (
              <li key={index}>
                <h3>
                  <BiRadioCircle className={classes.deptdetailsicon} />
                  {facility.split(" - ")[0]}
                </h3>
                <p>{facility.split(" - ")[1]}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Facilitiesdetail;
