import React, { useEffect } from "react";
import classes from "./Home.module.css";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import train from "../../assets/Railway.svg"
import flight from "../../assets/Airport.svg"

import AOS from "aos";
import "aos/dist/aos.css";

function Nearbyhome() {
    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
        AOS.refresh();
      });
    return (
        <>
            <div className={classes.nearby} data-aos="fade-up">
                <h1>Places Nearby</h1>
                <p>
                Conveniently located, our hospital is easily accessible via the nearby railway station and airport, 
                ensuring hassle-free travel for patients and visitors.
                </p>
                <Grid container spacing={4} data-aos="fade-up">
                    <Grid item xs={12} md={7}>
                        <iframe
                            title="Google Map"
                            width="100%"
                            height="450"
                            frameBorder="0"
                            src="https://maps.google.com/maps?q=mes%20hospital%20Valanchery&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                            allowFullScreen
                        ></iframe>
                    </Grid>
                    <Grid item xs={12} md={5} data-aos="fade-up">
                        <Grid container spacing={2} className={classes.nearbylistitem}>
                            <Grid item xs={2}>
                                <img src={train} alt="train" width={"100%"} />
                            </Grid>
                            <Grid item xs={10}>
                                Angadippuram Railway Station
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.nearbylistitem}>
                            <Grid item xs={2}>
                                <img src={flight} alt="flight" width={"100%"} />
                            </Grid>
                            <Grid item xs={10}>
                                Calicut International Airport
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.nearbylistitem}>
                            <Grid item xs={2}>
                                <img src={train} alt="train" width={"100%"} />
                            </Grid>
                            <Grid item xs={10}>
                                Angadippuram Bus Station
                            </Grid>
                        </Grid>
                        {/* <p className={classes.viewbtn}>
                            <Link to="/">View All &nbsp;<BsArrowRight /></Link>
                        </p> */}
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Nearbyhome;