import React from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Home from '../pages/home/Home';
import About from '../pages/aboutus/About';
import Contact from '../pages/contactus/Contact';
import Department from '../pages/department/Department'
import MultiSpeciality from '../pages/department/Multispeciality'
import Generalmedicine from '../pages/department/generalmedicine/Generalmedicine'
import Doctors from "../pages/doctors/Doctors";
import Doctordetail from "../pages/doctors/Doctordetail";
import Departmentdetail from "../pages/department/Departmentdetail";
import NewsandEvents from "../pages/news&events/NewsandEvents"
import NewsandEventsDetail from "../pages/news&events/NewsandEventsDetail"
import Blog from "../pages/blog/Blog"
import BlogDetail from "../pages/blog/BlogDetail";
import PatientDetail from "../pages/patients/PatientDetail";
import Appointment from "../pages/appointment"
import Gallery from "../pages/gallery/Gallery"
import Facilities from "../pages/facilities/Facilities";
import Facilitiesdetail from "../pages/facilities/Facilitiesdetail";
import Patients from "../pages/patients/Patients";

import Award from "../pages/awards/Award";
import Insurance from "../pages/insurance/Insurance";
import Career from "../pages/career/Career";
import CareerDetail from "../pages/career/CareerDetail";
import Highlights from "../pages/highlights/Highlights"
import Ebrochure from "../pages/Ebrochure/Ebrochure"
import Privacy from "../pages/privacy&disclaimer/Privacy";
import Disclaimer from "../pages/privacy&disclaimer/Disclaimer";
import HealthCheckup from "../pages/healthcheckup/HealthCheckup";
import Tips from "../pages/tipsfromDoctors/Tips";
import TipsDetail from "../pages/tipsfromDoctors/TipsDetail";
import Administration from "../pages/administrationteam/Administration";
import SpecialSchemes from "../pages/specialschemes/SpecialSchemes";
import ImagesbyAlbum from "../pages/gallery/ImagesbyAlbum";
import Clinics from "../pages/clinics/Clinics"
import Pharmacy from "../pages/pharmacy/Pharmacy"
export default function Navigate() {
    return (
        <Router>
            <div>
                <div><Header/></div>
                <Routes>
                    <Route exact path="/" element={<Home />}></Route>
                    <Route path="/about" element={<About/>}></Route>
                    <Route path="/contact" element={<Contact/>}></Route>
                    <Route path="/department" element={<Department/>}></Route>
                    <Route path="/multi-speciality" element={<MultiSpeciality/>}></Route>
                    <Route path="/generalmedicine" element={<Generalmedicine/>}></Route>
                    <Route path="/medical-team" element={<Doctors/>}></Route>
                    <Route path="/doctors/:slug" element={<Doctordetail/>}></Route>
                    <Route path="/department/:slug" element={<Departmentdetail/>}></Route>
                    <Route path="/news-events" element={<NewsandEvents/>}></Route>
                    <Route path="/news-events/:slug" element={<NewsandEventsDetail/>}></Route>
                    <Route path="/blog" element={<Blog/>}></Route>
                    <Route path="/blog/:slug" element={<BlogDetail/>}></Route>
                    <Route path="/appointment" element={<Appointment/>}></Route>
                    <Route path="/gallery" element={<Gallery/>}></Route>
                    <Route path="/clinics" element={<Clinics/>}></Route>
                    <Route path="/facilities" element={<Facilities/>}></Route>
                    <Route path="/facilities/:id" element={<Facilitiesdetail/>}></Route>
                    <Route path="/patients" element={<Patients/>}></Route>
                    <Route path="/patients/:id" element={<PatientDetail/>}></Route>
                    <Route path="/awards-achievements" element={<Award/>}></Route>
                    <Route path="/insurance" element={<Insurance/>}></Route>
                    <Route path="/career" element={<Career/>}></Route>
                    <Route path="/pharmacy" element={<Pharmacy/>}></Route>
                    <Route path="/career/:id" element={<CareerDetail/>}></Route>
                    <Route path="/highlights" element={<Highlights />}></Route>
                    <Route path="/ebrochure" element={<Ebrochure />}></Route>
                    <Route path="/privacy-policy" element={<Privacy />}></Route>
                    <Route path="/disclaimer" element={<Disclaimer />}></Route>
                    <Route path="/health-checkup-packages" element={<HealthCheckup />}></Route>
                    <Route path="/health-tips" element={<Tips/>}></Route>
                    <Route path="/health-tips/:slug" element={<TipsDetail/>}></Route>
                    <Route path="/administration-team" element={<Administration/>}></Route>
                    <Route path="/special-schemes" element={<SpecialSchemes/>}></Route>
                    <Route path="/images-album/:id" element={<ImagesbyAlbum/>}></Route>
                </Routes>
                <div><Footer/></div>
            </div>
        </Router>

    );
}