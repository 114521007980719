import React from "react";
import classes from "./Highlights.module.css";
import highlightsbannerimg from "../../assets/banner5.jpg";
import { AiFillStar } from "react-icons/ai";
import Grid from "@mui/material/Grid";

function Highlights(props) {
  return (
    <>
      <div
        className={classes.highlightsbanner}
        style={{ backgroundImage: `url(${highlightsbannerimg})` }}
      >
        <div className={classes.highlightsbanneroverlay}>
          <div className={classes.highlightsbannercon}>
            <h1>Highlights</h1>
            <p>
              <strong>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui.
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.highlightsbody}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <div className={classes.highlightslistcon}>
              <h3><AiFillStar /> Infrastructure</h3>
              <p>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui.
              </p>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={classes.highlightslistcon}>
              <h3><AiFillStar /> Central laboratory</h3>
              <p>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui.
              </p>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={classes.highlightslistcon}>
              <h3>
                <AiFillStar /> Peripheral center
              </h3>
              <p>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui.
              </p>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={classes.highlightslistcon}>
              <h3><AiFillStar /> Funding your treatment</h3>
              <p>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui.
              </p>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={classes.highlightslistcon}>
              <h3><AiFillStar /> Funding your treatment</h3>
              <p>
                Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                dolore ercit ati on ull amco laboris nisi ut aliqui.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Highlights;
