import React, { useEffect } from "react";
import classes from "./Home.module.css";
import Grid from "@mui/material/Grid";
import hospitalimg from "../../assets/Asset1.jpg"
import Achivements from "../../assets/Mess Achivements.svg"
import bed from "../../assets/Bed-capacity.png"
import cured from "../../assets/cured-patients.png"
import special from "../../assets/Super-speciality.png"
import AOS from "aos";
import "aos/dist/aos.css";

function Welcome() {
    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
        AOS.refresh();
      });
    return (
        <>
            <div className={classes.welcomesection}>
                <div className={classes.imggradient}  data-aos="fade-up">
                    <img src={hospitalimg} alt="hospital" className={classes.welcomeimg} />
                </div>
                <div className={classes.welcomecont}  data-aos="fade-up">
                    <h1>Welcome to<br></br><strong>MES Hospital</strong></h1>
                    <p>MES Medical College Hospital, established in 2003, is a multi-super speciality hospital dedicated to delivering top-tier patient care.
                        Our mission at MES Hospital is to ensure the availability of the highest quality healthcare services and facilities to the community.
                        We are committed to promoting wellness, alleviating suffering, and restoring health swiftly and compassionately, regardless of age,
                        gender, or creed. Our approach revolves around delivering competent and innovative services infused with a compassionate touch.
                        We are also dedicated to educating the public about illness prevention, empowering them to maintain lifelong health.</p>
                    <Grid container spacing={2} className={classes.welcomeachive} style={{alignItems:"end"}}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Grid container spacing={2} style={{alignItems:"end"}}>
                                <Grid item xs={4}>
                                    <img src={bed} alt="Achivements" width={"100%"} />
                                </Grid>
                                <Grid item xs={8}  className={classes.welcomeachivecont}>
                                    <h1>660+</h1>
                                    <h3>Bed Capacity</h3>
                                    {/* <p>dolors sit amet, consectetur</p> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Grid container spacing={2} style={{alignItems:"end"}}>
                                <Grid item xs={4}>
                                    <img src={cured} alt="Achivements" width={"100%"} />
                                </Grid>
                                <Grid item xs={8}  className={classes.welcomeachivecont}>
                                    <h1>6M+</h1>
                                    <h3>Cured Patients</h3>
                                    {/* <p>dolors sit amet, consectetur</p> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Grid container spacing={2} style={{alignItems:"end"}}>
                                <Grid item xs={4}>
                                    <img src={special} alt="Achivements" width={"100%"} />
                                </Grid>
                                <Grid item xs={8}  className={classes.welcomeachivecont}>
                                    <h1>14+</h1>
                                    <h3>Super Speciality</h3>
                                    {/* <p>dolors sit amet, consectetur</p> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default Welcome;
