import React, { useEffect, useState } from "react";
import classes from "./Gallery.module.css";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";

function Videos(props) {
    const { isLoading, error, data } = useQuery("VideoData", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/videos").then((res) =>
      res.json()
    )
  );
  const [items, setItems] = useState(data);
  if (isLoading) return "Loading...";
  
  if (error) return "An error has occurred: " + error.message;
  return (
    <Grid container spacing={2}>
    {data.albums.data.map((item,i) => (
      <Grid item md={4} key={i}>
        <div
          
          style={{ cursor: "pointer" }}
        >
          <div className={classes.Galleryeach}>
            
              <div className={classes.videoContainer}>
                <iframe
                  title={item.description}
                  width="100%"
                  height="100%"
                  src={item.video_url}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            
            <p>{item.description}</p>
          </div>
        </div>
      </Grid>
    ))}
  </Grid>
  )
}

export default Videos