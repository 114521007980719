import React from "react";
import { useState } from "react";
import Deptsdata from "./Deptdata";
import classes from "./Deptlist.module.css";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

function Deptlist(props) {
  const [items, setItems] = useState(Deptsdata);
  const { isLoading, error, data } = useQuery("deptlist", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/departments").then((res) =>
    res.json()
  )
);

if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <div className={classes.deptlistcontainer}>
        <h1>Our Department</h1>
        <div className={classes.deptlistcontainerStyle}>
            {data.data.map((item) => {
                const { id, title, image } = item;

                return (
                    <div key={id} className={classes.deptlistflexItemStyle} >
                        <h3>{item.name}</h3>
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/" + item.logo} alt={title} className={classes.deptlistimg} />
                        <p>
                        <Link to={`/department/${item.slug}`} className={classes.deptlisturl}>
                            Read More
                            </Link>
                            </p>
                    </div>
                );
              })}
            
        </div>
      </div>
    </>
  );
}

export default Deptlist;
