import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../assets/messtrip.png";
import Grid from "@mui/material/Grid";
import Asset8 from "../../assets/Asset8.svg"
import Asset9 from "../../assets/Asset9.svg"
import Appointment from "../../assets/Asset10.svg"
import Patients from "../../assets/Asset11.svg"
import Call from "../../assets/Asset12.svg"
import Sidebar from "./Sidebar";



function Header() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="headerright">
        <Link to="/contact">Need help?</Link>
        <Link to="/career">Careers</Link>
        <Link to="/contact">Contact Us</Link>
        <Link to="/contact">Leave Feedback</Link>
      </div>
      <div className="headersecond">
        <Grid container spacing={2} className="headersecondgrid">
          <Grid item xs={12} md={4}>
          <Link to="/">
            <img className="headerlogo" src={logo} alt=""></img>
            </Link>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} className="headersecondgrid1">
              <Grid item xs={4} md={2}>
                <Link to="/department">
                  <Grid container spacing={2} className="headersecondgriditem">
                    <Grid item xs={5}>
                     <img src={Asset8} alt="Specialities" width={"100%"} />
                    </Grid>
                    <Grid item xs={7} className="headersecondtitle">
                      Our<br></br>Specialities
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={4} md={2}>
                <Link to="/clinics">
                  <Grid container spacing={2} className="headersecondgriditem">
                    <Grid item xs={5}>
                     <img src={Asset9} alt="Clinic" width={"100%"} />
                    </Grid>
                    <Grid item xs={7} className="headersecondtitle">
                      Our<br></br>Clinics
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              
              <Grid item xs={4} md={2}>
                <Link to="/appointment">
                  <Grid container spacing={2} className="headersecondgriditem">
                    <Grid item xs={5}>
                    <img src={Appointment} alt="Appointment" width={"100%"} />
                    </Grid>
                    <Grid item xs={7} className="headersecondtitle">
                      Book<br></br>Appointment
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={4} md={2}>
                <Link to="/insurance">
                  <Grid container spacing={2} className="headersecondgriditem">
                    <Grid item xs={5}>
                    <img src={Patients} alt="Patients" width={"100%"} />
                    </Grid>
                    <Grid item xs={7} className="headersecondtitle">
                    Insurance<br></br>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={4} md={2}>
                <Link to="/contact">
                  <Grid container spacing={2} className="headersecondgriditem">
                    <Grid item xs={5}>
                    <img src={Call} alt="Call" width={"100%"} />
                    </Grid>
                    <Grid item xs={7} className="headersecondtitle">
                    Booking Number
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={4} md={2}>
                <Sidebar />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Header;
