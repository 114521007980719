import React, { useEffect, useState } from "react";
import classes from "./Home.module.css";
import Deptsdata from "../department/Deptdata";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { BsArrowRight } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useQuery } from "react-query";
import AOS from "aos";
import "aos/dist/aos.css";
function Depthome(props) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  });
  const { isLoading, error, data } = useQuery("department", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/departments").then((res) =>
      res.json()
    )
  );

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  // const [items, setItems] = useState(Deptsdata);

  return (
    <>
      <div className={classes.dochomecontainer}  data-aos="fade-up">
        <h1>Our Specialities</h1>
        <p>
          With expertise in 20+ specialities, we deliver complete medical and
          surgical solutions, connecting our specialities to deliver superior
          treatment and care to our patients.
        </p>
        <p className={classes.viewbtn}>
          <Link to="/department">
            View All &nbsp;
            <BsArrowRight />
          </Link>
        </p>

        <OwlCarousel
          items={4}
          className="owl-theme"
          data-aos="fade-up"
          loop
          nav
          margin={8}
          autoplay={true}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            900: {
              items: 3,
            },
            1200: {
              items: 4,
            },
          }}
        >
          {data.data.map((item, i) => {
                        // const {id, title, image, description } = elem;
          return (

            <div className="item">
              <Link
                to={`/department/${item.slug}`}
                key={i}
                className={classes.blogurl}
              >
                <div className={classes.deptItem}>
                  <div className={classes.deptItemimg}>
                    <img
                      src={process.env.REACT_APP_PUBLIC_URL + "/" + item.logo}
                      alt={item.title}
                      className={classes.deptimg}
                     style={{height:"60px",width:"70px" ,padding:"5px"}}
                    />
                  </div>
                  <h2 style={{"text-transform": "capitalize"}}>{item.name}</h2>
                  <p><div dangerouslySetInnerHTML={{__html: item.description.slice(0, 100),
                             }} ></div></p>
                             {/* <div className={classes.dcgtitle}>{item.name}</div> */}
                             <div className={classes.dcgimg}> <div className={classes.deptItemimg}>
                    <img
                      src={process.env.REACT_APP_PUBLIC_URL + "/" + item.logo}
                      alt={item.title}
                      className={classes.deptimg}
                     style={{height:"60px",width:"70px" ,padding:"5px"}}
                    />
                  </div></div>
                              <div className={classes.dcgcontent}>{item.name}</div>
                              <div className={classes.dcgcontentls} ><div  dangerouslySetInnerHTML={{__html: item.description.slice(0, 100),
                             }} ></div></div>
                              <div className={classes.dcgoverlay}></div>
                </div>
              </Link>
            </div>
          );
           })}
        </OwlCarousel>

        {/* <Grid container spacing={2}>
                    {items.map((elem) => {
                        const { id, title, image, description } = elem;

                        return (
                            <Grid item xs={12} md={3} key={id} style={{ marginTop: '20px' }}>
                                <Link
                                    to={`/blog/${id}`}
                                    className={classes.blogurl}
                                >
                                    <div className={classes.blogItem}>
                                        <img
                                            src={image}
                                            alt={title}
                                            className={classes.blogimg}
                                        />
                                        <h2>{title}</h2>
                                        <p>{description}</p>
                                    </div>
                                </Link>
                            </Grid>
                        );
                    })}
                </Grid> */}
      </div>
    </>
  );
}

export default Depthome;
