const InsuranceData = [
  {
    id: 501,
    title: "ERICSON TPA INSURANCE TPA PVT LTD ",
    description: 
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "https://mediassist.in/",
  },
  {
    id: 502,
    title: "ADITYA BIRLA HEALTH INSURANCE Co. Ltd ",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 503,
    title: "FUTURE GENERALI INDIA INSURANCE COMPANY LTD ",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 504,
    title: "CARE HEALTH INSURANCE (RELIGARE)",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 505,
    title: "IFFKO TOKIO GENERAL INSURANCE COMPANY",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 506,
    title: "LIBERTY GENARAL INSURANCE",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 507,
    title: "PARAMOUNT HEALTH SERVICES & INSURANCE TPA P LTD ",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 508,
    title: "VIDAL HEALTH INSURANCE ΤΡΑ",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 509,
    title: "HERITAGE HEALTH INSURANCE TPA PVT LTD ",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 510,
    title: "STAR HEALTH",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 511,
    title: "FAMILY HEALTH PLAN INSURANCE TPA LTD ",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 512,
    title: "CHOLAMANDALAM MS GENERAL INSURANCE COMPANY LTD ",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 513,
    title: "RAKSHA HEALTH INSURANCE TPA PVT LTD",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 514,
    title: "HEALTH INDIA TPA SERVICES PVT LTD",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 515,
    title: "MEDI ASSIST HEALTH INSURANCE",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 516,
    title: "SBI HEALTH INSURANCE",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 517,
    title: " TATA AIG GENERAL INSURANCE COMPANY",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 518,
    title: "I HEALTH CARE -ICICI LOMBARD GIC LTD",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 519,
    title: "GO DIGIT",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
  {
    id: 520,
    title: "UNIVERSAL SOMPO GENERAL INSURANCE CO LTD",
    description:
      "Lorem ipsum dolors sit amet, Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    url: "#",
  },
];

export default InsuranceData;
