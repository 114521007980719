import React, { useState } from "react";
import classes from "./Home.module.css";
import Grid from "@mui/material/Grid";
import Bannerimg from "../../assets/banner.jpg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MdPlayArrow } from "react-icons/md";
import { useQuery } from "react-query";
import MenuItem from '@mui/material/MenuItem';
import Homesearch from "./Homesearch";
import Carousel from "react-material-ui-carousel";

function Bannerhome(props) {

  const { isLoading, error, data } = useQuery("banner", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/home/banner").then((res) =>
      res.json()
    )
  );

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      <Carousel>
        {data.data.map((item, i) => {
          return (
            <div
              className={classes.homebanner}
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_PUBLIC_URL + "/" + item.image
                })`,
              }}
            >
              <div className={classes.homebanneroverlay}>
                <div className={classes.homebannercon}>
                  {/* <h1>{item.title}</h1> */}
                  <p>
                    <strong>{item.description}</strong>
                  </p>
                </div>
                <Homesearch />
              </div>
            </div>
          );
        })}
        {/* <div
                className={classes.homebanner}
                style={{ backgroundImage: `url(${Bannerimg})` }}
            >
                <div className={classes.homebanneroverlay}>
                    <div className={classes.homebannercon}>
                        <h1>Touching lives<br></br>with caring hands</h1>
                        <p>
                            <strong>Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                                eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                                amco laboris nisi ut aliqui.</strong>
                        </p>
                    </div>
                    <div className={classes.homebannersearch}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '22ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <span>Specialities:</span><TextField className={classes.homesearchfeild} label="Search Specialities" variant="filled" />
                            <span>Find a Doctor:</span><TextField className={classes.homesearchfeild} label="Search Doctor" variant="filled" />
                            <Button className={classes.homesearchbtn} variant="contained">Search <MdPlayArrow /></Button>
                        </Box>
                    </div>
                </div>
            </div> */}
      </Carousel>
    </>
  );
}

export default Bannerhome;
