const HealthCheckupData = [
  {
    id: 1,
    title: "EXECUTIVE HEALTH CHECK UP",
    price: "4000/-",
    consult: "Physician, ENT, Ophthalmology, Gynecology (For Females), Dietician",
    benefits: [
      "CBC - ESR",
      "BLOOD GROUP & RH",
      "FBS",
      "PPBS",
      "UREA",
      "CREATININE",
      "URIC ACID",
      "LIPID PROFILE",
      "LFT",
      "ELECTROLYTES (SODIUM, POTTASSIUM, CHLORIDE, BICARBONATE, AGPK, AGP, URINE RE, HBSAG HCV,TSH, PAP SMEAR (FOR LADIES) & PSA (FOR MEN ABOVE 50 YEARS))",
      "CHEST X-RAY",
      "ECG",
      "ECHO",
      "TMT",
      "USG ABDOMEN",
      "DENTAL SCREENING"
    ],
  },
  
  {
    ID: 2,
    title: "STANDARD HEALTH CHECK UP",
    consult: "Physician, ENT, Ophthalmology, Gynecology (For Females)",
    price: "3300/-",
    benefits: [
      "CBC-ESR",
      "BLOOD GROUP & RH",
      "FBS",
      "PPBS",
      "UREA",
      "CREATININE",
      "URIC ACID",
      "LIPID PROFILE",
      "LFT",
      "ELECTROLYTES (SODIUM, POTTASSIUM, CHLORIDE, BICARBONATE, AGPK, AGP, URINE RE, HBSAG, HCV,TSH, PAP SMEAR (FOR LADIES), PSA (FOR MEN ABOVE SO YEARS)",
      "CHEST X-RAY",
      "ECG",
      "USG ABDOMEN",
      "DENTAL SCREENING"
    ]
  },
  {
    id: 3,
    title: "DIABETIC HEALTH CHECK UP",
    consult: "Diabetology. Ophthalmology, Dietician",
    price: "3300/-",
    benefits: [
      "CBC-ESR",
      "BLOOD GROUP & RH",
      "FBS",
      "PPBS",
      "UREA",
      "CREATININE",
      "URIC ACID",
      "LIPID PROFILE",
      "HBAIC ELECTROLYT ES (SODIUM, POTTASSIUM, CHLORIDE, BICARBONATE, AGPK AGP, URINE RE, URINE MICROALBUMIN, CHEST XRAY, ECG, ECHO, TMT, NCVS, REFRACTION STUDY, DENTAL SCREENING",
    ],
  },
  {
    id: 4,
    title: "JUNIOR HEALTH CHECK UP",
    consult: "Paediatric, Ophthalmology, ENT",
    price: "3000/-",
    benefits: [
      "CBC-ESR",
      "BLOOD GROUP & RH",
      "RBS",
      "UREA",
      "CREATININE",
      "LIPID PROFILE",
      "LFT",
      "ELECTROLYTES (SODIUM,POTTASSIUM CHLORIDE, BICARBONATE, AGPK, AGP)",
      "URINE RE",
      "HBSAG",
      "HCV",
      "TFT",
      "CHEST X-RAY",
      "ECG",
      "ECHO",
      "USG ABDOMEN",
      "DENTAL SCREENING"
    ],
  }
];

export default HealthCheckupData;
