import React, { useState, useRef, useEffect } from "react";
import classes from "./Contact.module.css";
import contactbannerimg from "../../assets/contact.jpg";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaMapMarkerAlt, FaPhoneAlt, FaMailBulk } from "react-icons/fa";
import axios from "axios";

function Contact() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [create, setCreate] = useState("");

  const nameInput = useRef(null);
  const emailInput = useRef(null);
  const messageInput = useRef(null);
  const phoneInput = useRef(null);

  useEffect(() => {
    // Reset state after a delay when create message is set
    if (create) {
      const timeoutId = setTimeout(() => {
        setCreate("");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setError("");
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [create]);

  async function loginHandle(e) {
    e.preventDefault();

    try {
      var filter =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (Name.length === 0) {
        setError("Please enter your Full Name");
        nameInput.current.focus();
      } else if (Name !== Name.trim()) {
        setError("Space is not allowed first in Name");
        nameInput.current.focus();
      } else if (Email.length === 0) {
        setError("Please enter your E-mail Address");
        emailInput.current.focus();
      } else if (!filter.test(Email)) {
        setError("This is not a valid email format!");
        emailInput.current.focus();
      } else if (Phone.length === 0) {
        setError("Please enter your Phone Number");
        phoneInput.current.focus();
      } else if (!/^\d{10}$/.test(Phone)) {
        setError("Please enter a valid 10-digit Phone Number");
        phoneInput.current.focus();
      } else if (Message.length === 0) {
        setError("Please enter your Message");
        messageInput.current.focus();
      } else if (Message !== Message.trim()) {
        setError(
          "Please write something without leading or trailing white spaces"
        );
        messageInput.current.focus();
      } else {
        // Make a POST request to the Laravel API endpoint
        const response = await axios.post(
          process.env.REACT_APP_PUBLIC_API_URL + "/contact/",
          {
            name: Name,
            email: Email,
            phone: Phone,
            message: Message,
          }
        );

        // Handle the API response as needed
        console.log(response.data);
        setCreate("Your Message Successfully Sent");

        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setError(""); // Clear any previous errors

        // Reset the form (clear all input fields)
        e.target.reset();
      }
    } catch (error) {
      // Handle errors
      console.error("Error submitting contact form:", error);
    }
  }

  return (
    <>
      <div
        className={classes.contactbanner}
        style={{ backgroundImage: `url(${contactbannerimg})` }}
      >
        <div>
          <div className={classes.contactbannercon}>
            <p>
              <strong></strong>
            </p>
          </div>
        </div>
      </div>

      <div className={classes.contactbox}>
        <Grid container spacing={0}>
          <Grid item md={6}>
            <div className={classes.address}>
              <div className={classes.location}>
                <FaMapMarkerAlt className={classes.iconstyleone} />
                <div className={classes.locationcon}>
                  Valanchery - Nilambur Road, Palachode Post, Malaparamba,
                  Perinthalmanna, Kolathur, Malappuram, Kerala 679321, India
                </div>
              </div>
              <div className={classes.location}>
                <FaPhoneAlt className={classes.iconstyle} />
                <div className={classes.locationcon}>
                  +91 4933 298300, 333, 444 <br></br>
                  Admission Direct Lines: +91 4933 298310
                </div>
              </div>
              <div className={classes.location}>
                <FaMailBulk className={classes.iconstyle} />
                <div className={classes.locationcon}>
                  mesmc@mesams.com <br></br>admission@mesams.com
                </div>
              </div>
            </div>
          </Grid>

          <Grid item md={6}>
            <div className={classes.formsection}>
              <form onSubmit={loginHandle}>
                <TextField
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  value={Name}
                  id="outlined-basic"
                  label="Name"
                  inputRef={nameInput}
                  variant="outlined"
                  className={classes.textfield}
                  sx={{
                    width: "80%",
                    marginTop: "20px",
                    "& .MuiInputLabel-root.Mui-focused": { color: "#800020" },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        border: "3px solid #800020",
                      },
                    },
                  }}
                />
                {error && nameInput.current === document.activeElement && (
                  <div className={classes.error}>{error}</div>
                )}
                <TextField
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  value={Email}
                  id="outlined-basic"
                  label="E-mail"
                  inputRef={emailInput}
                  variant="outlined"
                  className={classes.textfield}
                  sx={{
                    width: "80%",
                    marginTop: "20px",
                    "& .MuiInputLabel-root.Mui-focused": { color: "#800020" },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        border: "3px solid #800020",
                      },
                    },
                  }}
                />
                {error && emailInput.current === document.activeElement && (
                  <div className={classes.error}>{error}</div>
                )}

                <TextField
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                  value={Phone}
                  id="outlined-basic"
                  label="Phone Number"
                  inputRef={phoneInput}
                  variant="outlined"
                  className={classes.textfield}
                  sx={{
                    width: "80%",
                    marginTop: "20px",
                    "& .MuiInputLabel-root.Mui-focused": { color: "#800020" },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        border: "3px solid #800020",
                      },
                    },
                  }}
                />
                {error && phoneInput.current === document.activeElement && (
                  <div className={classes.error}>{error}</div>
                )}

                <TextField
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  value={Message}
                  id="outlined-multiline-static"
                  label="Write your message here"
                  inputRef={messageInput}
                  multiline
                  rows={5}
                  className={classes.textfield}
                  sx={{
                    width: "80%",
                    marginTop: "20px",
                    "& .MuiInputLabel-root.Mui-focused": { color: "#800020" },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        border: "3px solid #800020",
                      },
                    },
                  }}
                />
                {error && messageInput.current === document.activeElement && (
                  <div className={classes.error}>{error}</div>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  className={classes.textfield}
                  sx={{
                    width: "50%",
                    height: "56px",
                    marginTop: "20px",
                    fontSize: "18px",
                    backgroundColor: "#800020",
                    "&:hover": {
                      background: "#800020",
                    },
                    letterSpacing: "1.5px",
                  }}
                >
                  Send
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>

      <div>
        <iframe
          title="Google Map"
          width="100%"
          height="500"
          frameBorder="0"
          src="https://maps.google.com/maps?q=mes%20hospital%20Valanchery&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

export default Contact;
