import axios from "axios";

const before = interceptor => {
    return axios.interceptors.request.use(interceptor);
};

const after = interceptor => {
    return axios.interceptors.response.use(interceptor);
};

const create = values => {
    const data = axios.post(process.env.REACT_APP_PUBLIC_API_URL + `/appointment`, values);
    return data;
};

export default {
    create,
    before,
    after,
};