import React, { useEffect, useState } from "react";
import classes from "./Gallery.module.css";
import Gallerybannerimg from "../../assets/banner6.jpg";
import GalleryData from "./GalleryData";
import Grid from "@mui/material/Grid";
import Images from "./Images";
import Videos from "./Videos";

function Gallery(props) {

  const [showComponent1, setShowComponent1] = useState(true);
  const [showComponent2, setShowComponent2] = useState(false);

  const toggleComponent1 = () => {
    setShowComponent1(!showComponent1);
    setShowComponent2(false); 
  };

  const toggleComponent2 = () => {
    setShowComponent1(false); // Hide the first component
    setShowComponent2(!showComponent2);
  };

  return (
    <div className={classes.Galleryfullbody}>
      <div
        className={classes.Gallerybanner}
        style={{ backgroundImage: `url(${Gallerybannerimg})` }}
      >
        <div className={classes.Gallerybanneroverlay}>
          <div className={classes.Gallerybannercon}>
            <h1>Gallery</h1>
            <p>
              <strong></strong>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.Gallerybody}>
        <div className={classes.GalleryFilterbutton}>
          <button onClick={toggleComponent1}>
            Image
          </button>
          <button
onClick={toggleComponent2}
          >
            Video
          </button>
        </div>
        {showComponent1 && <Images />}
        {showComponent2 && <Videos />}
      </div>

    </div>
  );
}

export default Gallery;
