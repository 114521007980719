import React from "react";
import "./Footer.css";
import logo from "../../assets/mesWhite.png";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Link } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

import { BsEnvelope } from "react-icons/bs";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: adds smooth scrolling behavior
    });
  };
  return (
    <>
      <div className="footerbody">
        <img className="footerlogo" src={logo} alt=""></img>
        <Grid container spacing={2}>
        
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className="h-2">
                <h2>Quick Links</h2>
                <Link to="/medical-team" onClick={scrollToTop}>Find a Doctor</Link>
                <br></br>
                <Link to="/appointment" onClick={scrollToTop}>Book an Appointment</Link>
                <br></br>
                <Link to="/about" onClick={scrollToTop}>Management team</Link>
                <br></br>
                <Link to="/contact" onClick={scrollToTop}>Make an Enquiry</Link>
                <br></br>
                <Link to="/contact" onClick={scrollToTop}>Leave a Feedback</Link>
                <br></br>
                
                <Link to="/insurance" onClick={scrollToTop}>Insurance</Link>
                <br></br>
                <Link to="/career" onClick={scrollToTop}>
                  Careers</Link>
                <br></br>
               
                <Link to="/">Admission & Discharge</Link>
                <br></br>
                <Link to="/">Guidelines for Patient & Attendants</Link>
                <br></br>
                <Link to="/">Patient Rights and Responsibilities</Link>
                <br></br>

              </Grid>
              <Grid item xs={12} md={8}>
              <h2>Our Specialties</h2>
              <Grid container spacing={2}>
              <Grid item xs={12} md={6} className="h-2">
                
                <Link to="#" onClick={scrollToTop}>Emergency Medicine</Link>
                <br></br>
                <Link to="#" onClick={scrollToTop}>General Medicine</Link>
                <br></br>
                <Link to="/department/pulmonology" onClick={scrollToTop}>Pulmonology </Link>
                <br></br>
                <Link to="/department/dermatology-cosmetology" onClick={scrollToTop}>Dermatology & Cosmetology</Link>
                <br></br>
                <Link to="/department/paediatric-surgery" onClick={scrollToTop}>Paediatrics & Neonatology</Link>
                <br></br>
                <Link to="/department/ent-head-neck-surgery" onClick={scrollToTop}>ENT, Head & Neck Surgery</Link>
                <br></br>
                <Link to="/department/ophthalmology" onClick={scrollToTop}>Ophthalmology</Link>
                <br></br>
                <Link to="/department/obstetrics-gynaecology" onClick={scrollToTop}>Obstetrics & Gynaecology</Link>
                <br></br>
                <Link to="/department/orthopaedics-traumatology" onClick={scrollToTop}>Orthopaedics & Traumatology</Link>
                <br></br>
                <Link to="/department/psychiatry" onClick={scrollToTop}>Psychiatry & Psychology</Link>
                <br></br>
                <Link to="/department/radiology" onClick={scrollToTop}>Radiology</Link>
                <br></br>
                
               </Grid>
              <Grid item xs={12} md={6} className="h-2">
              <Link to="/department/plastic-surgery" onClick={scrollToTop}>Paediatric Surgery</Link>
              <br></br>
                <Link to="/department/urology" onClick={scrollToTop}>Urology & Andrology</Link>
                <br></br>
                <Link to="/department/obstetrics-gynaecology" onClick={scrollToTop}> Gastroenterology</Link>
                <br></br>
                <Link to="/department/neurology" onClick={scrollToTop}>Neurology</Link>
                <br></br>
                <Link to="/department/neuro-surgery" onClick={scrollToTop}>Neuro Surgery</Link>
                <br></br>
                <Link to="/department/endocrine-surgery" onClick={scrollToTop}>Endocrine Surgery</Link>
                <br></br>
                <Link to="/department/cardiology" onClick={scrollToTop}>Cardiology</Link>
                <br></br>
                <Link to="/department/nephrology-dialysis" onClick={scrollToTop}>Nephrology & Dialysis</Link>
                <br></br>
                {/* <Link to="#" onClick={scrollToTop}>Plastic Surgery & Cosmetic Surgery</Link>
                <br></br> */}
                <Link to="/department/oncology" onClick={scrollToTop}>Oncology</Link>
                <br></br>
               
                <Link to="/department/endocrinology" onClick={scrollToTop}>Endocrinology</Link>
                <br></br>
                {/* <Link to="#" onClick={scrollToTop}>Vascular Surgery</Link>
                <br></br> */}
                {/* <Link to="#" onClick={scrollToTop}>Diabetology</Link>
                <br></br> */}
                <Link to="/department/hand-reconstructive-surgery" onClick={scrollToTop}>Hand Reconstructive Surgery</Link>
                <br></br>
                
              </Grid>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className="h-2">
            <h2>Our clinics</h2>
            <p className="m-0"><Link to="/clinics" onClick={scrollToTop}>MES Health Centre,Pandikkad</Link></p>
            <p className="m-0"><Link to="/clinics" onClick={scrollToTop}>MES Health Centre,Pattikad</Link></p>
            <p className="m-0"><Link to="/clinics" onClick={scrollToTop}>MES Health Centre,Perinthalmanna</Link></p>
            <p className="mb-6"><Link to="/clinics" onClick={scrollToTop}>MES Health Centre, Attapadi</Link></p>
            <List sx={{ width: "100%" }} className="contactlistcontainer">
            <b>MES Medical College Hospital</b>
              <ListItem className="contactlist">
                {/* <ListItemAvatar>
                  <FaMapMarkerAlt className="contactlisticon" />
                </ListItemAvatar> */}
               
                <ListItemText primary="Valanchery - Nilambur Road, Palachode Post, Malaparamba, Perinthalmanna, Kolathur, Malappuram, Kerala 679321, India" />
              </ListItem>
              <ListItem className="contactlist">
                {/* <ListItemAvatar>
                  <FaPhoneAlt className="contactlisticon" />
                </ListItemAvatar> */}
                <ListItemText
                 
                 primary="Booking Number :  +91 4933 298310 "
                 secondary="+91 4933 298300, 333, 444 "

                />
              </ListItem>
              <ListItem className="contactlist">
                {/* <ListItemAvatar>
                  <BsEnvelope className="contactlisticon" />
                </ListItemAvatar> */}
                <ListItemText
                  // primary="appointment@mesams.com"
                  // secondary="admission@mesams.com"
                />
              </ListItem>
            </List>
            <p className="footersocialicons">
              <Link to="https://www.facebook.com/mesmchofficial?mibextid=ZbWKwL"> <FaFacebookF className="contactsocialicon" /></Link> 
              <Link to="https://www.instagram.com/mes_medical_college_hospital/?igsh=NXM5ODlrejl2eHU2"><FaInstagram className="contactsocialicon" /> </Link>
              <Link to="https://www.linkedin.com/in/mes-medical-college-hospital-4aa501ab/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><FaLinkedinIn className="contactsocialicon" /> </Link>
             
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="footerbody2">
        
        <p>
          © 2024 All rights reserved
        </p>
      </div>
    </>
  );
}

export default Footer;
