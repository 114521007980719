import React from "react";
import classes from "./Department.module.css";
import deptbannerimg from "../../assets/dp.jpg";
import deptimg from "../../assets/medical.jpg";
import Grid from "@mui/material/Grid";
import Depts from './Depts'
import Multidepts from "./Multidepts";

function Department() {
  return (
    <>
      <div
        className={classes.deptbanner}
        style={{ backgroundImage: `url(${deptbannerimg})` }}
      >
        <div >
          <div className={classes.deptbannercon}>
            {/* <h1>Departments</h1> */}
            {/* <p>
              <strong>Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
              eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
              amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
              volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
              adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
              dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur
              tu dolo end erit in volup tate velit ese.</strong>
            </p> */}
          </div>
        </div>
      </div>

      {/* <div className={classes.deptbody}>
        <h1>Lorem ipsum dolors sit amet</h1>
        <Grid container spacing={2} className={classes.deptgrid}>
          <Grid item md={6}>
          <img
              className={classes.deptimage}
              src={deptimg}
              alt=""
            ></img>
            
          </Grid>
          <Grid item md={6}>
          <p>
              Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
              eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
              amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
              volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
              adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
              dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur
              tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
              adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
              dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur
              tu dolo end erit in volup tate velit ese.
            </p>
            <p>
              Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
              eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
              amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
              volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
              adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
              dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur
              tu dolo end erit in volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
              adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
              dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur
              tu dolo end erit in volup tate velit ese.
            </p>
          </Grid>
        </Grid>
      </div> */}
      <div>
      <h1> Specialty Departments</h1>
        <Depts />
        <h1>Super Specialty Departments</h1>
        <Multidepts />
      </div>
    </>
  );
}

export default Department;
