import React from "react";
import classes from "./Department.module.css";
import deptbannerimg from "../../assets/banner3.jpg";
import deptimg from "../../assets/medical.jpg";
import Grid from "@mui/material/Grid";
import Multidepts from './Multidepts'


function MultiSpeciality() {
  return (
    <>
      <div
        className={classes.deptbanner}
        style={{ backgroundImage: `url(${deptbannerimg})` }}
      >
        <div className={classes.deptbanneroverlay}>
          <div className={classes.deptbannercon}>
            <h1>Multispeciality</h1>
          </div>
        </div>
      </div>
      <div>
        <Multidepts />
      </div>
    </>
  );
}

export default MultiSpeciality;
