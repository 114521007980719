import React from "react";
import classes from "./HealthCheckup.module.css";
import healthbannerimg from "../../assets/hp.jpg";
import HealthCheckupData from "./HealthCheckupData";
import { useState } from "react";
import { BiRadioCircle } from "react-icons/bi";

function HealthCheckup(props) {
  const [items, setItems] = useState(HealthCheckupData);

  return (
    <>
      <div
        className={classes.healthbanner}
        style={{ backgroundImage: `url(${healthbannerimg})` }}
      >
        <div>
          <div className={classes.healthbannercon}>
          
          </div>
        </div>
      </div>
      <div className={classes.healthbody}>
        {items.map((elem) => {
          const { id, title, price, consult, benefits } = elem;
          return (
            <div key={id} className={classes.HealthCheckupitem}>
              <h2>
                {title} <span>(INR : {price})</span>
              </h2>

              <ul style={{ listStyleType: "none" }} className={classes.benefitslist}>
                {benefits.map((benefit, index) => (
                    <li key={index}>
                        <p><BiRadioCircle className={classes.deptdetailsicon} /> {benefit}</p>
                    </li>
                ))}                
              </ul>
              <h4>
                Consultation :  <span>{consult}</span>
              </h4>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default HealthCheckup;
