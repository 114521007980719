import doctorimg from "../../assets/doc1.jpg";
import doctorimg1 from "../../assets/doc2.jpg";
import doctorimg2 from "../../assets/doc3.jpg";
import doctorimg3 from "../../assets/doc4.jpg";
import doctorimg4 from "../../assets/doctor.jpg";
import doctorimg5 from "../../assets/doctor1.jpg";
import doctorimg6 from "../../assets/doctor2.jpg";

const Docs = [
  {
    id: 111,
    name: "Dr. Abdul Waheed Ansari",
    image: doctorimg,
    department: "Interventional Cardiologist",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 222,
    name: "Dr. Ahmed Subir",
    image: doctorimg1,
    department: "Interventional Cardiologist",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 333,
    name: "Dr. Afeena",
    image: doctorimg2,
    department: "Interventional Cardiologist",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 444,
    name: "Dr. Aloka Joy",
    image: doctorimg3,
    department: "Interventional Cardiologist",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 555,
    name: "Dr. Dejo",
    image: doctorimg4,
    department: "Otology",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 666,
    name: "Dr. Rejitha",
    image: doctorimg5,
    department: "Pulmonology",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 777,
    name: "Dr. Milan",
    image: doctorimg6,
    department: "Urology",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 888,
    name: "Dr. Roshin",
    image: doctorimg1,
    department: "Dental Care",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 999,
    name: "Dr. Bribin",
    image: doctorimg2,
    department: "Orthopedics",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
  {
    id: 1010,
    name: "Dr. Sumi",
    image: doctorimg,
    department: "Eye Care",
    description:
      "Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in volup tate velit ese.",
    qualification: "MBBS",
  },
];

export default Docs;
