import brochurePdf from "../../assets/brochure/sample.pdf";
import brochureimg1 from "../../assets/news.jpg";
import brochureimg2 from "../../assets/news1.jpg";

const EbrochureData = [
  {
    id: 1,
    title: "Ebrochur",
    image: brochureimg1,
    pdf: brochurePdf,
  },
  {
    id: 2,
    title: "Ebrochur1",
    image: brochureimg2,
    pdf: brochurePdf,
  },
  {
    id: 3,
    title: "Ebrochur2",
    image: brochureimg1,
    pdf: brochurePdf,
  },
  {
    id: 4,
    title: "Ebrochur3",
    image: brochureimg2,
    pdf: brochurePdf,
  },
];

export default EbrochureData;
