import React, { useEffect, useState } from "react";
import classes from "./Home.module.css";
import Docs from "../doctors/doctorsdata";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { BsArrowRight } from "react-icons/bs";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useQuery } from 'react-query'
import AOS from "aos";
import "aos/dist/aos.css";
function Doctorshome(props) {
    //const [items, setItems] = useState(Docs);
    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
        AOS.refresh();
      });
    const { isLoading, error, data } = useQuery("DoctorList", () =>
    fetch(process.env.REACT_APP_PUBLIC_API_URL + "/doctors").then((res) =>
      res.json()
    )
  );
  const [items, setItems] = useState(data);
  if (isLoading) return "Loading...";
  
  if (error) return "An error has occurred: " + error.message;
 
    return (
        <>
            <div className={classes.dochomecontainer} data-aos="fade-up">
                <h1>Our Doctors</h1>
                <p>
                    MES is a trusted destination for medical solutions, thanks to our team of skilled and experienced doctors.
                    Patients looking for relief from their health problems can rely on our specialized doctors,
                    who bring years of experience and provide the best of solutions and care.
                </p>
                <p className={classes.viewbtn}>
                    <Link to="/medical-team">View All &nbsp;<BsArrowRight /></Link>
                </p>
                <OwlCarousel
                    items={4}
                    className="owl-theme"
                    data-aos="fade-up"
                    loop
                    nav
                    margin={8}
                    autoplay ={true}
                    responsive={{
                        0: {
                            items: 1,
                        },
                        600: {
                            items: 2,
                        },
                        900: {
                            items: 3,
                        },
                        1200: {
                            items: 4,
                        },
                    }}
                    >
                    {data.data.map((item,i) => {
                      
                        return (
                            <div key={i} className="item">
                                <Link to={`/doctors/${item.slug}`} className={classes.blogurl}>
                                    <div className={classes.docItem}>
                                        <img src={process.env.REACT_APP_PUBLIC_URL + "/" + item.image} alt={item.name} className={classes.docimg} />
                                        <h2>{item.name}</h2>
                                        <p>Qualification - {item.qualifications}</p>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </OwlCarousel>

                {/* <Grid container spacing={2}>
                    {items.map((elem) => {
                        const { id, name, image, department } = elem;

                        return (
                            <Grid item xs={12} md={3} key={id} style={{ marginTop: '20px' }}>
                                <Link
                                    to={`/blog/${id}`}
                                    className={classes.blogurl}
                                >
                                    <div className={classes.blogItem}>
                                        <img
                                            src={image}
                                            alt={name}
                                            className={classes.blogimg}
                                        />
                                        <h2>{name}</h2>
                                        <p>Consultant - {department}</p>
                                    </div>
                                </Link>
                            </Grid>
                        );
                    })}
                </Grid> */}
            </div>
        </>
    );
}

export default Doctorshome;
