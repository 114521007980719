import React from "react";
import { useParams } from "react-router-dom";
import NewsData from "./NewsData";
import classes from "./NewsandEvents.module.css";
import newsbannerimg from "../../assets/banner4.jpg";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";

const NewsandEventsDetail = () => {
  const { slug } = useParams();


const { isLoading, error, data } = useQuery("Newsdetail", () =>
fetch(process.env.REACT_APP_PUBLIC_API_URL + "/news-and-events/" + slug).then((res) =>
  res.json()
)
);
if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;

  // if (!data) {
  //   return <div style={{ margin: "50px" }}>No Data not found.</div>;
  // }
  return (
    <>
      <div
        className={classes.newsbanner}
        style={{ backgroundImage: `url(${newsbannerimg})` }}
      >
        <div className={classes.newsbanneroverlay}>
          <div className={classes.newsbannercon}>
            <h1>News & Events</h1>
            
          </div>
        </div>
      </div>

      <div className={classes.newsdetailbody}>
        <div className={classes.newsdetailcon}>
          <h1>{data.data.title}</h1>
          <p>{data.data.date.slice(0,10)}</p>
        </div>
        <Divider />
        <div className={classes.newsdetailcon}>
          <img
            src={process.env.REACT_APP_PUBLIC_URL + "/" + data.data.image}
            alt={data.data.title}
            className={classes.newsdetailimg}
          />
          <p><div dangerouslySetInnerHTML={{__html: data.data.description }}></div></p>
        </div>
      </div>
    </>
  );
};

export default NewsandEventsDetail;
