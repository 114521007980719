import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Deptsdata from "./Deptdata";
import docbannerimg from "../../assets/dp.jpg";
import classes from "./Department.module.css";
import Deptlist from "./Deptlist";
import Grid from "@mui/material/Grid";
import { BiRadioCircle } from "react-icons/bi";
import Docs from "../doctors/doctorsdata";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";


const Departmentdetail = () => {
  
  const { slug } = useParams();


  useEffect(() => {
    console.log(slug);
  }, []);

  const { isLoading, error, data } = useQuery("deptdetails", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/departments/"+slug).then((res) =>
    res.json()
  )
);
if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;

  // const department = Deptsdata.find((item) => item.id === parseInt(id));

  // if (!department) {
  //   return <div style={{ margin: "50px" }}>Department not found.</div>;
  // }

  return (
    <>
      <div
        className={classes.deptbanner}
        style={{ backgroundImage: `url(${docbannerimg})` }}
      >
        <div className={classes.deptbanneroverlay}>
          <div className={classes.deptbannercon}>
            {/* <h1>Department</h1>
              <p>
                <strong>
                  Lorem ipsum dolors sit amet, cons ectetur adipisci elit, sed do
                  eiusmod tempor inc ididunt ut labores et dolore ercit ati on ull
                  amco laboris nisi ut aliqui. Dui aute irur tu dolo end erit in
                  volup tate velit ese. Lorem ipsum dolors sit amet, cons ectetur
                  adipisci elit, sed do eiusmod tempor inc ididunt ut labores et
                  dolore ercit ati on ull amco laboris nisi ut aliqui. Dui aute
                  irur tu dolo end erit in volup tate velit ese.
                </strong>
              </p> */}
          </div>
        </div>
      </div>

      <Grid container  className={classes.deptdetails}>
        <Grid item md={12}>

          <div className={classes.deptdetailscon}>
          
            <h1>{data.data.name}</h1>
            <p><div dangerouslySetInnerHTML={{__html: data.data.description }}></div></p>
            <ul
              style={{ listStyleType: "none" }}
              className={classes.deptdetailslist}
            >
              {/* {data.features.map((feature, index) => (
                <li key={index}>
                  <BiRadioCircle className={classes.deptdetailsicon} />
                  {feature}
                </li>
              ))} */}
            </ul>
          </div>


        </Grid>
        {/* <Grid item md={7}>
          <div className={classes.deptdetailsimgdiv}>
            <img
              className={classes.deptdetailsimage}
              src={process.env.REACT_APP_PUBLIC_URL + "/" + data.data.logo}
              height={500}
              width={500}
              alt={data.title}
            ></img>
          </div>
        </Grid> */}
      </Grid>
      <div className={classes.doctorsList}>
      <h1>Doctors in Our Department</h1> 
      <div className={classes.doctorItem}>
        {data.data.doctors.map(
          (doctor) => (
            <div key={doctor.id} >
                <Card className={classes.docscard}>
                  <CardMedia component="img" alt={doctor.name} image={process.env.REACT_APP_PUBLIC_URL + "/" + doctor.image}  style={{"height": "310px"}}/>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {doctor.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {doctor.department}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Link to={`/doctors/${doctor.slug}`}>
                      <Button size="small">Learn More</Button>
                    </Link>
                  </CardActions>
                </Card>
              </div>
          )
        )}
      </div>
      <div className={classes.appoinmentbtndiv}>
          <a className={classes.appoinmentbtn} href="/appointment">
            <strong>Book An Appoinment</strong>
          </a>
        </div>
      </div>
    </>
  );
};

export default Departmentdetail;
