import React from "react";
import classes from "./NewsandEvents.module.css";
import newsbannerimg from "../../assets/banner4.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";

function NewsandEvents(props) {
  const { isLoading, error, data } = useQuery("Newsdata", () =>
  fetch(process.env.REACT_APP_PUBLIC_API_URL + "/news").then((res) =>
    res.json()
  )
);
const [items, setItems] = useState(data);
if (isLoading) return "Loading...";

if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <div
        className={classes.newsbanner}
        style={{ backgroundImage: `url(${newsbannerimg})` }}
      >
        <div className={classes.newsbanneroverlay}>
          <div className={classes.newsbannercon}>
            <h1>News & Events</h1>
          
          </div>
        </div>
      </div>

      <div className={classes.newscontainerStyle}>
        <Grid container spacing={2}>
          {data.data.map((elem,i) => {
            return (
              <Grid item md={4} key={i}>
                <div className={classes.newsItem}>
                  <h2>{elem.title}</h2>
                  <img
                    src={process.env.REACT_APP_PUBLIC_URL + "/" + elem.image}
                    alt={elem.title}
                    className={classes.newsimg}
                  />
                  <p><div dangerouslySetInnerHTML={{__html: elem.description }}></div></p>
                  <Grid container spacing={2}>
                  <Grid xs={6} >
                    <h4>{elem.date.slice(0,10)}</h4>
                  </Grid>
                  <Grid xs={6} >
                    <h4>
                    <Link
                      to={`/news-events/${elem.slug}`}
                      className={classes.newsurl}
                    >
                      Read More
                    </Link>
                    </h4>
                  </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
}

export default NewsandEvents;
